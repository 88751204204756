import React, { useState } from 'react';
import { useBooleanToggle } from '@mantine/hooks';
import { AdditionalAddressForm } from 'shared/UserDetails/containers/shared/AdditionalAddress/AdditionalAddressForm';
import { AdditionalAddressList } from 'shared/UserDetails/containers/shared/AdditionalAddress/AdditionalAddressList';

export const AdditionalAddress = () => {
    const [showForm, toggleForm] = useBooleanToggle(false);
    const [address, setAddress] = useState({});

    return (
        <div>
            {showForm
                ? <AdditionalAddressForm address={address} setAddress={setAddress} toggleForm={toggleForm} />
                : <AdditionalAddressList setAddress={setAddress} toggleForm={toggleForm} />}
        </div>
    );
};
