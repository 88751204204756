import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { select } from '@rematch/select';
import _ from 'lodash';
import { userSelectors, userActions } from '../stores/user';
import UserForm from './shared/UserForm';
import UserContractList from './commune/UserContractList';
import EmployerBenefitDecisionList from './commune/EmployerBenefitDecisionList';
import UserTradeUnionMembership from './shared/UserTradeUnionMembership';
import UserWagesCertificateReport from './shared/UserWagesCertificateReport';
import UserAccidentInsurances from './shared/UserAccidentInsurances';
import ChangePassword from './private/ChangePassword';
import ChangeLocale from './private/ChangeLocale';
import Gdpr from './private/Gdpr';
import { LeavePeriodContainer } from './shared/LeavePeriodContainer';
import EmployeeWorkExperience from 'shared/UserDetails/containers/shared/EmployeeWorkExperience';
import { WeeklyHoursStatement } from 'shared/UserDetails/containers/shared/WeeklyHoursStatement';
import PayrollList from 'shared/UserDetails/containers/shared/PayrollList';
import userRoles from 'shared/constants/userRoles';
import { Foreclosure } from 'shared/UserDetails/containers/shared/Foreclosure';
import SideBar from 'shared/components/SideBar';
import Page from 'shared/components/Page';
import MDSpinner from 'shared/components/MDSpinner';
import { actions as notificationActions } from 'shared/stores/notifications';
import { routeKeys } from 'User/constants/routes';
import { ActionBar, Button, Feedback, GoBackLink } from 'shared/components';
import confirm from 'shared/utils/confirm';
import resolveUrl from 'shared/utils/resolveUrl';
import routes from 'Commune/BenefitDecision/routes';
import AcceptedCorrectionDebtList from 'PayrollCorrection/Debt/CorrectionsOverviewTabs/containers/AcceptedCorrectionDebtList';
import EmptyState from 'shared/components/EmptyState';
import { UserAttachedDimensions } from 'shared/UserDetails/containers/company/UserAttachedDimensions';
import { dimension } from 'shared/shapes/dimensions';
import { ChangeUsername } from 'shared/UserDetails/containers/private/ChangeUsername';
import { UserGroupHeader } from 'shared/UserDetails/containers/shared/UserGroupHeader';
import { UserDocuments } from 'shared/UserDetails/containers/shared/UserDocuments';
import { OnboardVismaConnect } from 'shared/UserDetails/containers/private/OnboardVismaConnect';
import ReportingForms from 'shared/UserDetails/containers/shared/ReportingForms';
import { templateSubTypes } from 'ContractV3/Builder/constants/templateTypes';
import { AdditionalAddress } from 'shared/UserDetails/containers/shared/AdditionalAddress';

/**
 * Pieni virityskomponentti joka yhdistää UserFormin storeen
 *
 * UserForm itsessään on oma kokonaisuutensa, keskustelee ulospäin propeilla.
 * Käytössä myös asiakkuus v2 formilla
 */
@connect((state) => ({
    initialUser: userSelectors.getUser(state),
    allowedUserDataSelfUpdateCustomerRegistryHyveNames: userSelectors.getAllowedUserDataSelfUpdateCustomerRegistryHyveNames(state),
    userId: userSelectors.getUserId(state),
    isEmployee: userSelectors.isEmployee(state),
    isCared: select.userMetadata.isCompany(state) && userSelectors.isCared(state),
    isBeneficiary: userSelectors.isBeneficiary(state),
    isEmployer: userSelectors.isEmployer(state),
    isCompany: select.userMetadata.isCompany(state),
    isCommune: select.userMetadata.isCommune(state),
    isPrivate: select.userMetadata.isPrivate(state),
    isCurrentUser: userSelectors.isCurrentUser(state),
    isDimensionPricingEnabled: select.userMetadata.isDimensionPricingEnabled(state),
    userContracts: state.userContracts,
    ui: userSelectors.getUiProps(state, 'userContracts'),
    isIdentified: select.userMetadata.isIdentified(state),
    loggedInUser: select.userMetadata.getMetadata(state),
    benefitDecisionId: userSelectors.getBenefitDecisionId(state),
    visibilityDimensions: select.dimensions.getVisibilityDimensions(state),
    isMonthlySalaryCustomerInvoicingEnabled: select.userMetadata.isMonthlySalaryCustomerInvoicingEnabled(state),
    isVtjEnabled: select.userMetadata.isVtjEnabled(state),
    isDocumentToolEnabled: select.userMetadata.isDocumentToolEnabled(state),
    isReportingFormsEnabled: select.userMetadata.isReportingFormsEnabled(state),
    isFetchingAllowedUserDataSelfUpdateCustomerRegistryHyveNames: userSelectors.isFetchingAllowedUserDataSelfUpdateCustomerRegistryHyveNames(state),
}), (dispatch) => ({
    addNotification: (notification) => dispatch(notificationActions.addNotification(notification)),
    onError: (notification) => dispatch(notificationActions.addNotification({ type: 'error', message: notification })),
    onUserUpdate: (user) => dispatch(userActions.setUser(user)),
    fetchContracts: (userId) => dispatch(userActions.fetchUserContracts(userId, 'userContracts')),
    deleteUser: (userId) => dispatch(userActions.deleteUser(userId)),
}))
class ConnectUserForm extends Component {
    static propTypes = {
        userId: PropTypes.number,
        allowedUserDataSelfUpdateCustomerRegistryHyveNames: PropTypes.array,
        fetchContracts: PropTypes.func,
        ui: PropTypes.instanceOf(Map),
        loggedInUser: PropTypes.instanceOf(Map),
        deleteUser: PropTypes.func,
        benefitDecisionId: PropTypes.number,
        isCurrentUser: PropTypes.bool,
        isPrivate: PropTypes.bool,
        isIdentified: PropTypes.bool,
        isMonthlySalaryCustomerInvoicingEnabled: PropTypes.bool,
        isPrivateUserDetails: PropTypes.bool,
        isFetchingAllowedUserDataSelfUpdateCustomerRegistryHyveNames: PropTypes.bool,
    };

    static defaultProps = {
        loggedInUser: Map({}),
        allowedUserDataSelfUpdateCustomerRegistryHyveNames: [],
        ui: Map(),
        userId: null,
        fetchContracts() {},
        deleteUser() {},
        benefitDecisionId: null,
        isCurrentUser: false,
        isPrivate: false,
        isIdentified: false,
        isMonthlySalaryCustomerInvoicingEnabled: false,
        isPrivateUserDetails: false,
        isFetchingAllowedUserDataSelfUpdateCustomerRegistryHyveNames: false,
    };

    componentDidMount() {
        this.props.fetchContracts(this.props.userId);
    }

    onDeleteUser = () => {
        const { userId, deleteUser } = this.props;
        confirm(_trans('userForm.delete.confirm.label'), {
            proceedLabel: _trans('userForm.delete.confirm.proceed'),
            cancelLabel: _trans('button.cancel'),
            alert: true,
        }).then(() => deleteUser(userId));
    };

    renderActionBar() {
        const hasBenefitDecisionLink = !! this.renderBenefitDecisionLink();
        return (
            <ActionBar hasMargin alignItems={hasBenefitDecisionLink ? '' : 'right'}>
                {this.renderBenefitDecisionLink()}
                {this.renderDeleteUserButton()}
            </ActionBar>
        );
    }

    renderDeleteUserButton() {
        if (this.props.loggedInUser.get('isCommuneAdmin', false)) {
            return (
                <Button
                    onClick={this.onDeleteUser}
                    admin
                >
                    {_trans('userForm.delete.label')}
                </Button>
            );
        }
        return null;
    }

    renderPrivateFeedback() {
        const { isPrivate, isCurrentUser, isIdentified, isPrivateUserDetails } = this.props;
        if ((isPrivate || isPrivateUserDetails) && isCurrentUser) {
            return (
                <Fragment>
                    {isIdentified && <Feedback
                        type="success"
                        title={_trans('user_data.user_is_identified')}
                    >
                        {_transMd('authentication.identified.message', {
                            termsOfUseUrl: 'https://www.oima.fi/kayttoehdot',
                        })}
                    </Feedback>}
                    {this.renderUserDataUpdateEffectHyveList()}
                </Fragment>
            );
        }
    }

    renderUserDataUpdateEffectHyveList() {
        const {
            allowedUserDataSelfUpdateCustomerRegistryHyveNames,
            isFetchingAllowedUserDataSelfUpdateCustomerRegistryHyveNames,
        } = this.props;
        const userDatasCount = allowedUserDataSelfUpdateCustomerRegistryHyveNames.userDatasNames?.length ?? 0;
        const vtjUserDatasNames = allowedUserDataSelfUpdateCustomerRegistryHyveNames.vtjUserDatasNames?.length ?? 0;

        if (isFetchingAllowedUserDataSelfUpdateCustomerRegistryHyveNames) {
            return null;
        }

        if (userDatasCount === 0 && vtjUserDatasNames === 0) {
            return (
                <Feedback type="warning" modifierClass="u-margin-top-small">
                    {_trans('Tietojen ei välitetä hyvinvointialueen asiakasrekisteriin. Ilmoita henkilötietojen ja tilinumeron muutokset hyvinvointialueelle.', {} , 'extract')}
                </Feedback>
            );
        }

        return (
            <Feedback type="info" modifierClass="u-margin-top-small">
                {_trans('Tietojen päivittäminen välitetään seuraavien hyvinvointialueiden asiakasrekistereihin:', {}, 'extract')}
                {userDatasCount > 0 && <div>
                    <ul>
                        {allowedUserDataSelfUpdateCustomerRegistryHyveNames.userDatasNames.map((allowedUserDataSelfUpdateCustomerRegistryHyveName, index) => (
                            <li key={index}>{allowedUserDataSelfUpdateCustomerRegistryHyveName}</li>
                        ))}
                    </ul>
                </div>}
                {vtjUserDatasNames > 0 &&
                    <div>
                        <div>{_trans('Paivitetään vain tilinumero', {}, 'extract')}</div>
                        <ul>
                            {allowedUserDataSelfUpdateCustomerRegistryHyveNames.vtjUserDatasNames.map((allowedUserDataSelfUpdateCustomerRegistryHyveName, index) => (
                                <li key={index}>{allowedUserDataSelfUpdateCustomerRegistryHyveName}</li>
                            ))}
                        </ul>
                    </div>
                }
            </Feedback>
        );
    }

    /**
     * Mahdollinen asiakkuus linkki
     *
     * @returns {*}
     */
    renderBenefitDecisionLink() {
        const { benefitDecisionId } = this.props;
        if (benefitDecisionId) {
            return (
                <a href={resolveUrl(routes.BENEFIT_DECISION_SUMMARY, { id: benefitDecisionId })}>
                    {_trans('benefit_decision.link')}
                </a>
            );
        }
    }

    render() {
        return (
            <Page heading={_trans('userForm.basicInfo.legend')} maxWidth="medium">
                {this.renderActionBar()}
                {this.renderPrivateFeedback()}
                {this.props.ui.get('isLoading', false)
                    ? <MDSpinner wrapped/>
                    : <UserForm {...this.props} isAssignmentContract={this.props.loggedInUser.get('allowAssignmentContracts', false)} />
                }
            </Page>
        );
    }
}

@connect((state) => ({
    initialUser: userSelectors.getUser(state),
    userRoles: userSelectors.getUserRoles(state),
    loggedInUser: select.userMetadata.getMetadata(state),
    isFetchingUser: userSelectors.isFetchingUser(state),
    hasMetadataFetched: select.userMetadata.hasMetadataFetched(state),
    isCompany: select.userMetadata.isCompany(state),
    isCared: userSelectors.isCared(state),
    isEmployee: userSelectors.isEmployee(state),
    isEmployer: userSelectors.isEmployer(state),
    isBeneficiary: userSelectors.isBeneficiary(state),
    userId: userSelectors.getUserId(state),
    isPrivate: select.userMetadata.isPrivate(state),
    routes: userSelectors.getRoutes(state),
    isCurrentUser: userSelectors.isCurrentUser(state),
    isAllowAssignmentContracts: select.userMetadata.allowAssignmentContracts(state),
    visibilityDimensions: select.dimensions.getVisibilityDimensions(state),
    visibilityDimensionTypeName: select.dimensions.getVisibilityDimensionTypeName(state),
    isDocumentToolEnabled: select.userMetadata.isDocumentToolEnabled(state),
    isReportingFormsEnabled: select.userMetadata.isReportingFormsEnabled(state),
    isMonthlySalaryCustomerInvoicingEnabled: select.userMetadata.isMonthlySalaryCustomerInvoicingEnabled(state),
    hasCustomerInvoicingArticleIdentifiers: select.userMetadata.hasCustomerInvoicingArticleIdentifiers(state),
}))
export default class ModuleManager extends Component {
    static propTypes = {
        initialUser: PropTypes.instanceOf(Map),
        userRoles: PropTypes.instanceOf(List),
        loggedInUser: PropTypes.instanceOf(Map),
        isFetchingUser: PropTypes.bool,
        hasMetadataFetched: PropTypes.bool,
        isCompany: PropTypes.bool,
        isCared: PropTypes.bool,
        isEmployee: PropTypes.bool,
        isEmployer: PropTypes.bool,
        isBeneficiary: PropTypes.bool,
        userId: PropTypes.number,
        isPrivate: PropTypes.bool,
        match: PropTypes.object,
        dispatch: PropTypes.func,
        routes: PropTypes.object,
        isCurrentUser: PropTypes.bool,
        isAllowAssignmentContracts: PropTypes.bool,
        visibilityDimensions: PropTypes.arrayOf(dimension),
        visibilityDimensionTypeName: PropTypes.string,
        isDocumentToolEnabled: PropTypes.bool,
        isReportingFormsEnabled: PropTypes.bool,
        isPrivateUserDetails: PropTypes.bool,
        isMonthlySalaryCustomerInvoicingEnabled: PropTypes.bool,
        hasCustomerInvoicingArticleIdentifiers: PropTypes.bool,
    };

    static defaultProps = {
        initialUser: Map({}),
        userRoles: List(),
        loggedInUser: Map(({})),
        isFetchingUser: false,
        hasMetadataFetched: false,
        isCompany: false,
        isCared: false,
        isEmployee: false,
        isEmployer: false,
        isBeneficiary: false,
        userId: null,
        isPrivate: null,
        match: {},
        dispatch: () => {},
        routes: {},
        isCurrentUser: false,
        isAllowAssignmentContracts: false,
        visibilityDimensions: [],
        visibilityDimensionTypeName: null,
        isDocumentToolEnabled: false,
        isReportingFormsEnabled: false,
        isPrivateUserDetails: false,
        isMonthlySalaryCustomerInvoicingEnabled: false,
        hasCustomerInvoicingArticleIdentifiers: false,
    };

    isConnectedToReactRouter = () => _.has(this.props, 'match.params.userId');

    componentDidMount() {
        const { match, dispatch, loggedInUser } = this.props;
        //Jos on User-kokonaisuutta (React-Routerin alla)
        if (this.isConnectedToReactRouter()) {
            const userId = _.get(match, 'params.userId', -1);
            dispatch(userActions.fetchUserDetails(userId));
            if (loggedInUser.get('isCommune', false)) {
                dispatch(userActions.fetchUserBenefitDecision(userId));
            }
        }

    }

    // Kokemuskuukausille tarkistusta pystyykö lisäämään/muokkaamaan niitä
    hasEditRole = () => (
        _.includes(
            [
                userRoles.COMMUNE,
                userRoles.COMMUNE_OPERATOR,
                userRoles.COMPANY_OPERATOR,
                userRoles.COMPANY_SHADOW
            ],
            this.props.loggedInUser.get('userRole')
        )
    );

    /**
     * Jaetut palkkamoduulit.
     */
    getSharedWageModules() {
        const { isEmployer, isEmployee, isAllowAssignmentContracts } = this.props;
        return [
            {
                routeName: 'wages_certificate',
                title: _trans('wages_certificates_report.wages'),
                content: <UserWagesCertificateReport />,
                visible: (isEmployer || isEmployee) && ! isAllowAssignmentContracts
            },
        ];
    }


    /**
     * Jaetut raporttimoduulit.
     */
    getSharedReportModules() {
        const { isEmployer } = this.props;
        return [
            {
                routeName: 'accident_insurances',
                title: _trans('users.accident_insurance.title'),
                content: <UserAccidentInsurances/>,
                visible: isEmployer,
            },
        ];
    }

    /**
     *
     * Kunta specifiset moduulit
     */
    getCommuneModules() {
        const { isEmployee, userId, loggedInUser, visibilityDimensions, visibilityDimensionTypeName, isDocumentToolEnabled, isReportingFormsEnabled, isEmployer } = this.props;
        const isCommuneAdmin = loggedInUser.get('isCommuneAdmin', false);
        const showReportingForms = isReportingFormsEnabled && (isEmployee || isEmployer);
        return [
            {
                routeName: 'user_form',
                title: _trans('userForm.basicInfo.legend'),
                content: <ConnectUserForm />,
            },
            {
                routeName: 'user_attached_dimensions',
                title: visibilityDimensionTypeName,
                content: <UserAttachedDimensions />,
                visible: visibilityDimensions.length > 0,
            },
            {
                routeName: 'work_experience',
                title: _trans('users.section.work_experience.employee_title'),
                content: <EmployeeWorkExperience userId={userId} hasEditRole={this.hasEditRole()}/>,
                visible: isEmployee,
            },
            {
                routeName: 'trade_union',
                title: _trans('trade_union_membership.name'),
                content: <UserTradeUnionMembership userId={userId} isAdmin={isCommuneAdmin}/>,
                visible: isEmployee,
            },
            {
                routeName: 'leave_periods',
                title: _trans('userForm.leavePeriod.title'),
                content: <LeavePeriodContainer />,
                visible: isEmployee,
            },
            {
                routeName: 'user_foreclosure',
                title: _trans('foreclosure.name'),
                content: <Foreclosure userId={userId} canEdit={isCommuneAdmin || loggedInUser.get('userRole', -1) === userRoles.COMMUNE_OPERATOR} />,
                visible: isEmployee,
            },
            {
                visible: isDocumentToolEnabled,
                routeName: 'documents_attachment',
                title:_trans('Dokumentit', {}, 'documents'),
                content: <UserDocuments />,
            },
            {
                visible: showReportingForms,
                routeName: 'reporting_forms',
                title:_trans('Lomakkeet', {}, 'common'),
                content: <ReportingForms
                    subType={isEmployee
                        ? templateSubTypes.EMPLOYEE
                        : templateSubTypes.EMPLOYER}
                />,
            },
            {
                groupName: _trans('Palkka', {}, 'common'),
                groupIcon: 'payments',
                routes: [
                    ...this.getSharedWageModules(),
                    {
                        routeName: 'payroll_list',
                        title: _trans('work_time.extra_action.payrolls'),
                        content: <PayrollList />,
                        visible: isEmployee,
                    },
                    {
                        routeName: 'weekly_hours_statement',
                        title: _trans('Viikkotuntiselvitys'),
                        content: <WeeklyHoursStatement userId={userId} />,
                        visible: isEmployee,
                    },
                ]
            },
            {
                groupName: _trans('reports.title'),
                groupIcon: 'bar_chart',
                routes: [
                    ...this.getSharedReportModules(),
                    // Tänne uudet henkilöön liittyvät raportit
                ]
            },
        ];
    }

    /**
     *
     * Yritys specifiset moduulit
     */
    getCompanyModules() {
        const { isCared, isEmployee, userId, loggedInUser, isAllowAssignmentContracts, visibilityDimensions, visibilityDimensionTypeName, isDocumentToolEnabled, isReportingFormsEnabled, hasCustomerInvoicingArticleIdentifiers } = this.props;
        const isAdmin = loggedInUser.get('isAdmin', false);

        return [
            {
                routeName: 'user_form',
                title: _trans('userForm.basicInfo.legend'),
                content: <ConnectUserForm />,
            },
            {
                routeName: 'user_attached_dimensions',
                title: visibilityDimensionTypeName,
                content: <UserAttachedDimensions />,
                visible: isAllowAssignmentContracts && visibilityDimensions.length > 0,
            },
            {
                routeName: 'trade_union',
                title: _trans('trade_union_membership.name'),
                content: <UserTradeUnionMembership userId={userId} isAdmin={isAdmin}/>,
                visible: isEmployee && ! isAllowAssignmentContracts,
            },
            {
                routeName: 'leave_periods',
                title: _trans('userForm.leavePeriod.title'),
                content: <LeavePeriodContainer />,
                visible: isEmployee && ! isAllowAssignmentContracts,
            },
            {
                routeName: 'debt',
                title: _trans('payroll.correction_debt.heading'),
                content: <AcceptedCorrectionDebtList employee={userId} />,
                // TODO siirrä kaikille näkyviin, kun palkankorjaus on valmiina suurelle yleisölle
                visible: isEmployee && isAdmin,
            },
            {
                routeName: 'user_foreclosure',
                title: _trans('foreclosure.name'),
                content: <Foreclosure userId={userId} canEdit isAllowAssignmentContracts={isAllowAssignmentContracts} />,
                visible: isEmployee,
            },
            {
                routeName: 'work_experience',
                title: _trans('users.section.work_experience.employee_title'),
                content: <EmployeeWorkExperience userId={userId} hasEditRole={this.hasEditRole()}/>,
                visible: isEmployee && ! isAllowAssignmentContracts,
            },
            {
                routeName: 'documents_attachment',
                title:_trans('Dokumentit', {}, 'documents'),
                content: <UserDocuments />,
                visible: isDocumentToolEnabled,
            },
            {
                routeName: 'additional_address',
                title: _trans('Osoitetiedot', {}, 'additionalAddress'),
                content: <AdditionalAddress />,
                visible: isCared && hasCustomerInvoicingArticleIdentifiers,
            },
            {
                routeName: 'reporting_forms',
                title: _trans('Lomakkeet', {}, 'reporting_forms'),
                content: (
                    <ReportingForms
                        subType={isCared
                            ? templateSubTypes.CARED
                            : templateSubTypes.FAMILY_CARER}
                    />
                ),
                visible: (isEmployee || isCared) && isReportingFormsEnabled,
            },
            {
                groupName: _trans('Palkka', {}, 'common'),
                groupIcon: 'payments',
                routes: [
                    ...this.getSharedWageModules(),
                    {
                        routeName: 'payroll_list',
                        title: _trans('work_time.extra_action.payrolls'),
                        content: <PayrollList payrollRoute="suoratyo_payment_details" />,
                        visible: isEmployee,
                    },
                    {
                        routeName: 'weekly_hours_statement',
                        title: _trans('Viikkotuntiselvitys', {}, 'common'),
                        content: <WeeklyHoursStatement userId={userId} />,
                        visible: isEmployee && ! isAllowAssignmentContracts,
                    },
                ]
            },
            {
                groupName: _trans('reports.title'),
                groupIcon: 'bar_chart',
                routes: [
                    ...this.getSharedReportModules(),
                ]
            }
        ];
    }

    /**
     *
     * Yksityispuolen specifiset moduulit
     */
    getPrivateModules() {
        const { isCurrentUser } = this.props;

        return [
            {
                routeName: 'user_form',
                title: _trans('userForm.basicInfo.legend'),
                content: <ConnectUserForm />,
            },
            {
                routeName: 'change_password',
                title: _trans('users.password_change.title'),
                content: <ChangePassword />,
                visible: isCurrentUser,
            },
            {
                routeName: 'change_username',
                title: _trans('Vaihda käyttäjätunnus', {}, 'common'),
                content: <ChangeUsername />,
                visible: isCurrentUser,
            },
            {
                routeName: 'change_locale',
                title: _trans('Vaihda asiointikieli', {}, 'common'),
                content: <ChangeLocale />,
                visible: isCurrentUser,
            },
            {
                routeName: 'vc_onboard',
                title: _trans('Visma Connect käyttöönotto', {}, 'common'),
                content: <OnboardVismaConnect />,
                visible: isCurrentUser,
            },
            {
                // todo: enabloi sitten, kun joku käyttäjä haluaa ladata tietonsa
                routeName: 'gdpr',
                title: _trans('user_data.gdpr.heading'),
                content: <Gdpr />,
                visible: isCurrentUser && false, // userId === 1234
            }
        ];
    }

    /**
     *
     * Kunta-admin puolen specifiset moduulit
     */
    getAdminModules() {
        const { isEmployer, isEmployee, userId } = this.props;
        return [
            {
                routeName: 'contract_list',
                title: _trans('userForm.contractList.title'),
                content: <UserContractList />,
                visible: isEmployer || isEmployee
            },
            {
                routeName: 'benefit_decision_list',
                title: _trans('benefit_decision.title'),
                content: <EmployerBenefitDecisionList />,
                visible: isEmployer,
            },
            // TODO siirrä kaikille näkyviin, kun palkankorjaus on valmiina suurelle yleisölle
            {
                routeName: 'debt',
                title: _trans('payroll.correction_debt.heading'),
                content: <AcceptedCorrectionDebtList employee={userId} />,
                visible: isEmployee,
            },
        ];
    }


    /**
     * Moduulien näyttö logiikka. Moduulit listataan taulukoihin ylempänä
     *
     * @returns {*[]}
     */
    resolveModules() {
        const { loggedInUser, isPrivateUserDetails } = this.props;

        let modules = [];

        //Kunta
        if (this.props.loggedInUser.get('isCommune', false) && ! isPrivateUserDetails) {
            modules = _.concat(modules, this.getCommuneModules());
        }

        //Yritys
        if (this.props.loggedInUser.get('userRole', -1) === userRoles.COMPANY_OPERATOR && ! isPrivateUserDetails) {
            modules = _.concat(modules, this.getCompanyModules());
        }

        //YP
        if (loggedInUser.get('isPrivate', false) || isPrivateUserDetails) {
            modules = _.concat(modules, this.getPrivateModules());
        }

        return modules;
    }

    render() {
        const {
            isFetchingUser,
            hasMetadataFetched,
            loggedInUser,
            routes,
            isPrivate,
            initialUser,
            isPrivateUserDetails,
        } = this.props;

        if (isFetchingUser || !hasMetadataFetched) {
            return <MDSpinner wrapped />;
        }

        // Jos käyttäjä on tyhjä => ei löytynyt. Kerrotaan ja tarjotaan paluulinkki.
        if (initialUser.size === 0) {
            return (
                <EmptyState
                    icon="avatar"
                    message={_trans('users.empty_state.text')}
                    callToAction={(
                        <GoBackLink
                            text={_trans('users.empty_state.back_to_user_list')}
                            to={routes.getIn([routeKeys.BASE, 'route'], null)}
                            modifierClass="c-side-bar__go-back-link u-text-left u-display-block"
                        />
                    )}
                />
            );
        }

        // Jos ei olla omissa tiedoissa näytetään KENEN tietoja ollaan muokkaamassa ettei tarvi
        // käydä mutkaa Perustiedoissa.
        const resolvedModules = [
            {
                ...isPrivate || isPrivateUserDetails
                    ? ({
                        groupName: _trans('user_data.heading'),
                        groupIcon: 'account_circle',
                    })
                    : ({
                        groupHeader: <UserGroupHeader initialUser={initialUser} />
                    }),
                routes: this.resolveModules(),
            },

        ];

        if (loggedInUser.get('isCommuneAdmin', false) && ! isPrivateUserDetails) {
            const adminModules =_.filter(this.getAdminModules(), (module) => module.visible);
            if (adminModules.length > 0) {
                resolvedModules.push({
                    groupName: 'ASPA',
                    groupIcon: 'android',
                    routes: adminModules,
                });
            }
        }

        return (
            <SideBar
                routes={resolvedModules}
                goBackRoute={routes.getIn([routeKeys.BASE, 'route'], null)}
                goBackText={_trans('users.empty_state.back_to_user_list')}
                maxContentWidth="large"
            />
        );
    }
}
