import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { FieldArray, useField } from 'formik';
import { SingleEmployee } from './SingleEmployee';
import Button from 'shared/components/Button';
import { FormField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { relationTypes } from 'shared/constants/communeCustomerRelationTypes';
import { metadataNames } from 'ContractV3/constants/metadataNames';

/**
 * Useamman hoitajan palikka.
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const Employee = ({ name, attributes, label }) => {
    const [field] = useField(name);
    const dispatch = useDispatch();

    const employees = field.value ?? [];
    const isNewContract = useSelector(select.contract.isNewContract);
    const benefitDecisionId = useSelector(select.contract.getBenefitDecisionId);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const genitive = _transObj(attributes?.translations?.genitive ?? { [_locale]: _trans('contract.employee.genitive') });

    const resolveAsyncEndPoint = () => {
        if (isEmployer) return `/api/v2/user/${benefitDecisionId}/find_by_ssn?`;
        return isCompany
            ? `/api/v2/users?type=${companyUserRelationTypes.RELATION_EMPLOYEE}&`
            : `/api/v2/users?type=${relationTypes.RELATION_EMPLOYEE}&`;
    };
    const placeholder = isEmployer
        ? _trans('Anna %genitive% henkilötunnus', { genitive: _transObj(genitive) }, 'jobContract')
        : _trans('Anna %genitive% nimi tai henkilötunnus', { genitive }, 'jobContract');

    const [contractGroupDivisorField,,contractGroupDivisorHelpers] = useField(fieldNames.CONTRACT_GROUP_DIVISORS);
    const contractGroupDivisors = contractGroupDivisorField.value ?? [];

    const [employeeAccessField,, employeeAccessHelpers] = useField(metadataNames.IS_EMPLOYEE_NOT_USING_OIMA);

    const isJobContract = useSelector(select.contract.isJobContract);

    useEffect(() => {
        if (! isNewContract && isJobContract && employees.length === 1) {
            if (isEmployer && benefitDecisionId) {
                dispatch.employeeWorkExperience.fetchEmployeeWorkExperienceByBenefitDecision(employees[0], benefitDecisionId);
            } else {
                dispatch.employeeWorkExperience.fetchEmployeeWorkExperience(employees[0]);
            }
        }
    }, [isNewContract, isJobContract, employees]);

    useEffect(() => {
        const contractGroupDivisorUserIds = contractGroupDivisors.map((divisor) => parseInt(divisor?.userId, 10));

        const newEmployees = employees.filter((userId) => (
            userId !== ''
            && ! contractGroupDivisorUserIds.includes(parseInt(userId, 10))
        ));

        if (newEmployees.length > 0 && isCompany) {
            newEmployees.map((userId) => {
                const existingDivisorUserIds = contractGroupDivisors.map((divisor) => divisor.userId);

                if (existingDivisorUserIds.includes(userId)) return;

                const defaultDivisor = contractGroupDivisors.length === 1
                    ? 100 - parseInt(contractGroupDivisors[0]?.divisor, 10)
                    : 100;

                contractGroupDivisorHelpers.setValue(
                    contractGroupDivisors.concat({
                        userId,
                        divisor: defaultDivisor,
                        costDivisor: defaultDivisor
                    })
                );
            });
        }
    }, [employees, contractGroupDivisors, isCompany]);

    const onRemoveParty = (index, arrayHelpers) => {
        const employeeUserId = parseInt(employees[index], 10);
        const remainingDivisors = contractGroupDivisors
            .filter((divisor) => parseInt(divisor?.userId, 10) !== employeeUserId);

        // Jos vaan yks hoitaja jää jäljelle pistellään sille kaikki palkkiot ja kulukorvaukset
        if (remainingDivisors.length === 1) {
            contractGroupDivisorHelpers.setValue([{
                ...remainingDivisors[0],
                divisor: 100,
                costDivisor: 100,
            }]);
        } else {
            contractGroupDivisorHelpers.setValue(remainingDivisors);
        }

        // Poistetaan myös "Ei käytä Oimaa -listalta
        if (Array.isArray(employeeAccessField.value) && employeeAccessField.value.length > 0) {
            employeeAccessHelpers.setValue(employeeAccessField.value
                .filter((userId) => parseInt(userId, 10) !== employeeUserId)
            );
        }

        arrayHelpers.remove(index);
        dispatch.employees.removeEmployeeAtIndex(index);
    };
    return (
        <div id={fieldNames.EMPLOYEE}>
            <FieldArray
                name={fieldNames.EMPLOYEE}
                render={(arrayHelpers) => (
                    <Fragment>
                        {employees.length === 0 && (
                            <SingleEmployee
                                name={`${name}.0`}
                                isSsnQuery={isEmployer}
                                rootName={name}
                                label={label}
                                attributes={attributes}
                                placeholder={placeholder}
                                asyncEndPoint={resolveAsyncEndPoint()}
                                onRemoveParty={() => onRemoveParty(0, arrayHelpers)}
                            />
                        )}
                        {employees.map((employee, index) => (
                            <Fragment key={index}>
                                <SingleEmployee
                                    key={index}
                                    name={`${name}.${index}`}
                                    isSsnQuery={isEmployer}
                                    rootName={name}
                                    label={label}
                                    attributes={attributes}
                                    placeholder={placeholder}
                                    asyncEndPoint={resolveAsyncEndPoint()}
                                    onRemoveParty={() => onRemoveParty(index, arrayHelpers)}
                                />
                                {/* Ensimmäistä ei voi poistaa */}
                                {(index > 0 && isNewContract) && (
                                    <FormField isContentFormField={false}>
                                        <Button mdIcon="clear" onClick={() => onRemoveParty(index, arrayHelpers)} flat>
                                            {_trans('Poista toinen hoitaja', {}, 'common')}
                                        </Button>
                                    </FormField>
                                )}
                            </Fragment>
                        ))}
                        {(employees.length < 2 && isCompany && isNewContract) && (
                            <FormField isContentFormField={false}>
                                <Button flat mdIcon="add" onClick={() => { arrayHelpers.push('');}}>
                                    {_trans('Lisää toinen hoitaja', {}, 'common')}
                                </Button>
                            </FormField>
                        )}
                    </Fragment>
                )}
            />
        </div>
    );
};

Employee.propTypes = {
    name: PropTypes.string.isRequired,
    attributes: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default Employee;
