import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    useTable,
    useSortBy,
    useFilters,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    useMountedLayoutEffect,
    useExpanded,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { DefaultColumnFilter } from './filters/DefaultColumnFilter';
import { CellRenderer } from './components/CellRenderer';
import { IndeterminateCheckbox } from './components/IndeterminateCheckbox';
import { setCustomFilters } from './filters/setCustomFilters';
import { useLocalStorage, useDebounce } from 'shared/hooks';
import Placeholder from 'shared/components/Placeholder';
import EmptyState from 'shared/components/EmptyState';
import MDIcon from 'shared/components/MDIcon';
import { Pagination } from 'shared/components/Pagination';
import { svgIconTypes } from 'shared/constants/index';
import { pageSizeOptions } from 'shared/components/Pagination';
import { Button } from 'shared/components';
import { resolveColumnIdentifier } from 'shared/containers/DataTable7/utils/resolveColumnIdentifier';
import { ColumnFilterList } from 'shared/containers/DataTable7/components/ColumnFilterList';
import ActionBar from 'shared/components/ActionBar';
import { DataTableExport } from 'shared/containers/DataTableExport';
import { get } from 'shared/utils/get';


const themes = ['default', 'compactBlank'];

const headerProps = (props, { column }) => getStyles(props, column);

const cellProps = (props, { cell }) => getStyles(props, cell.column);

const resolveAlign = (align) => {
    if (align === 'right') {
        return 'flex-end';
    }
    else if (align === 'center') {
        return 'center';
    }
    return 'flex-start';
};

// ReactTablen leveyden määrittäminen flexillä menee tällä hetkellä väärin. Korjataan tällä.
const getStyles = (props, column) => {
    const { align = 'left'/*, width*/ } = column;
    // const flexWidth = typeof width === 'number'
    //     ? `${width}px`
    //     : width;

    return [
        props,
        {
            style: {
                justifyContent: resolveAlign(align),
                alignItems: 'flex-start',
                display: 'flex',
                //flex: flexWidth ? `0 0 ${flexWidth}` : '1'
            },
        },
    ];
};

export const DataTable = ({
    data,
    columns,
    hasFilterableColumns,
    defaultPageSize,
    defaultSortBy,
    defaultSelectedRows,
    selectedRows,
    selectedRowKey,
    onSelect,
    resolveIsCheckboxRowDisabled,
    isManual,
    isSelectable,
    isSortable,
    isFilterable,
    isResizable,
    isLoading,
    emptyDataMessage,
    emptyDataSubText,
    emptyDataCallToAction,
    emptyDataIcon,
    hasPagination,
    actionsColumn,
    actionsColumnHeader,
    actionsColumnWidth,
    isActionsColumnSticky,
    isActionsColumnVisible,
    theme,
    getRowClassNames,
    id,
    hasPersistingTableState,
    renderSubComponent,
    canExport,
    exportFileName,
    exportRowFilter,
    exportColumnTotals,
    additionalExportColumns,
    isTotalVisible,
    hasVisibleHeaders,
}) => {
    const tableRef = useRef(null);
    // 0 = ei vieritystä kumpaankaan suuntaan
    // -1 = vieritetty vasemmalle
    // 1 = oikealle
    const [stickyState, setStickyState] = useState('');
    const [topScrollBarContent, setTopScrollbarContent] = useState(0);
    const [topScrollbarContainer, setTopScrollbarContainer] = useState(0);

    const topScrollbarRef = useRef(null);
    const rowRef = useRef(null);
    const topContentRef = useRef(null);

    if (hasFilterableColumns && (! id || id === '')) {
        console.error('hasFilterableColumns is true but no id given!');
    }
    const memoizedData = useMemo(() => data, [data]);
    const memoizedColumns = useMemo(() => {
        // Jos annettu toimintosarake liitetään se osaksi dataa
        if (actionsColumn && isActionsColumnVisible) {
            return setCustomFilters([
                ...columns,
                {
                    sticky: isActionsColumnSticky ? 'right' : undefined,
                    id: 'actions',
                    width: actionsColumnWidth,
                    Header: actionsColumnHeader,
                    hasWordWrap: true,
                    filterable: false,
                    sortable: false,
                    Cell: ({ row }) => typeof actionsColumn === 'function'
                        ? actionsColumn(row)
                        : React.cloneElement(actionsColumn, row),
                    isLocked: true, // actionsColumn aina lukittu
                    hideInExport: true,
                }
            ]);
        }

        return setCustomFilters(columns);
    }, [columns, actionsColumn, actionsColumnWidth, actionsColumnHeader, isActionsColumnVisible, isActionsColumnSticky]);

    const initialState = {
        pageIndex: 0,
        pageSize: defaultPageSize,
        sortBy: defaultSortBy,
    };

    const defaultFilteredColumns = useMemo(() => memoizedColumns
        .filter((column) => column.isVisibleByDefault !== true)
        .map(resolveColumnIdentifier)
        .filter(Boolean),
    [memoizedColumns]
    );

    const [persistedInitialState, setPersistedInitialState] = useLocalStorage(
        `tableState:${id}`,
        initialState
    );
    const [filteredColumns, setFilteredColumns] = useLocalStorage(
        `tableState:${id}.filteredColumns`,
        defaultFilteredColumns
    );

    // Sarakkeet
    const defaultHiddenColumns = useMemo(
        () => memoizedColumns.filter((col) => ! (col.show ?? true)).map(resolveColumnIdentifier),
        [memoizedColumns]
    );

    const onScroll = () => {
        const element = tableRef.current;
        if (! element) return;

        const hasReachedMin = element.scrollLeft === 0;
        const hasReachedMax = element.offsetWidth + element.scrollLeft >= element.scrollWidth;

        setStickyState(classNames({
            '-is-sticky-left': ! hasReachedMin,
            '-is-sticky-right': ! hasReachedMax,
        }));
    };

    // Onko yhden yhtään liimattua saraketta
    const hasStickyColumns = useMemo(() => (
        memoizedColumns.some((column) => (column.show ?? true) && column.sticky)
    ), [memoizedColumns]);

    // Tätä vähän seurattava aiheuttaako perffihittiä
    useEffect(() => {
        if (! isLoading && tableRef && hasStickyColumns) {
            onScroll();
            tableRef.current?.addEventListener('scroll', onScroll);
            const currentTableRef = tableRef.current;
            return () => {
                if (currentTableRef) {
                    currentTableRef.removeEventListener('scroll', onScroll);
                }
            };
        }
    }, [isLoading, hasStickyColumns]);

    useEffect(() => {
        if (rowRef.current && tableRef.current) {
            setTopScrollbarContainer(tableRef.current.scrollWidth);
            setTopScrollbarContent(rowRef.current.scrollWidth);
        }
    },[tableRef, rowRef, filteredColumns, isLoading]);

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 400,
            // Ei aseteta tarkkaa leveyttä. Flexbox osaa täyttää "aukot.
            //width: null,
            Filter: DefaultColumnFilter,
        }),
        []
    );

    // Piilottaa sarakkeet jotka on piilotettu mutta joilta löytyy id
    const hiddenColumns = columns
        .filter((col) => col?.show === false)
        .map((col) => col?.id ?? col?.Header);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows: tableRows,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        setPageSize,
        pageOptions,
        selectedFlatRows,
        toggleRowSelected,
        state,
        setAllFilters,
        filteredRows,
        preFilteredRows,
        toggleHideColumn,
        setHiddenColumns,
    } = useTable(
        {
            columns: memoizedColumns,
            data: memoizedData,
            defaultColumn,
            defaultSelectedRows,
            autoResetSelectedRows: false,
            autoResetFilters: false,
            autoResetPage: false,
            initialState: Object.assign(
                {},
                // Vain jos id asetettu, niin initial state
                id === '' || ! hasPersistingTableState ? initialState : persistedInitialState,
                {
                    selectedRowIds: defaultSelectedRows,
                    hiddenColumns: defaultHiddenColumns.concat(hasFilterableColumns ? filteredColumns : hiddenColumns),
                })
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useResizeColumns,
        useFlexLayout,
        useSticky,
        (hooks) => (
            isSelectable
                ? hooks.visibleColumns.push((columns) => [{
                    sticky: 'left',
                    id: 'selection',
                    // Checkbox headerissa: joko ruksittu, ei ruksittu tai "määrittelemätön" (indeterminate).
                    // eslint-disable-next-line react/prop-types,react/display-name
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps({ title: _trans('Vaihda rivien valinta.', {}, 'common') })} />
                    ),
                    /*Header: ({ rows, filteredFlatRows, ...rest }) => {
                        //const rows = filteredFlatRows;
                        console.log(rest);
                        // eslint-disable-next-line react/prop-types
                        const isRowDisabled = rows.filter((row) => resolveIsCheckboxRowDisabled(row?.original)).length !== 0;

                        // Mitkä rivit ovat valittavissa
                        const changeableRows = !isRowDisabled
                            ? [...rows]
                            // eslint-disable-next-line react/prop-types
                            : rows.filter((row) => ! resolveIsCheckboxRowDisabled(row?.original));

                        const allSelected = changeableRows.every((row) => row.isSelected);
                        const allUnselected = changeableRows.every((row) => !row.isSelected);

                        const modifiedToggleAllRowsProps = {
                            checked: ! allUnselected,
                            indeterminate: ! allSelected && ! allUnselected,
                            onChange: () =>
                                changeableRows.forEach((row) =>
                                    row.toggleRowSelected(!allSelected)
                                ),
                        };
                        return (
                            <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />
                        );
                    },*/
                    // Valinnan näyttäminen per rivi.
                    // eslint-disable-next-line react/display-name,react/prop-types
                    Cell: ({ row }) => {
                        // eslint-disable-next-line react/prop-types
                        const isDisabled = resolveIsCheckboxRowDisabled(row?.original);
                        return (
                            // eslint-disable-next-line react/prop-types
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: _trans('Vaihda rivien valinta.', {}, 'common'), disabled: isDisabled })} />
                        );
                    },
                    resizable: false,
                    disableResizing: true,
                    sortable: false,
                    minWidth: 40,
                    width: 40,
                    maxWidth: 40,
                }, ...columns])
                : null
        ),
    );

    const debouncedState = useDebounce(state, 500);

    useMountedLayoutEffect(() => {
        if (id === '' || ! hasPersistingTableState) {
            return;
        }

        const { sortBy, filters, pageSize, columnResizing, hiddenColumns, pageIndex } = debouncedState;
        const val = {
            sortBy,
            filters,
            pageSize,
            columnResizing,
            hiddenColumns,
            pageIndex,
        };
        setPersistedInitialState(val);

    }, [debouncedState]);

    // Jos ulkoisesti poistetaan jotain valintoja, kuunnellaan tässä muutoksia ja mahdollisesti ammutaan pois päältä.
    useMountedLayoutEffect(() => {
        if (selectedRowKey) {
            const [removed, added] = selectedFlatRows
                .map((row) => ({ key: row.original[selectedRowKey], id: row.id }))
                .filter((row) => row.key)
                // Jaotellaan tässä arvoja mitä pitää lisää ja mitä pitää poistaa
                .reduce(([removed, added], row) => {
                    if (selectedRows.includes(row.key)) {
                        return [
                            removed,
                            added.concat([row]),
                        ];
                    }

                    return [
                        removed.concat([row]),
                        added,
                    ];
                }, [[], []]);

            removed.forEach((row) => toggleRowSelected(row.id, false));
            added.forEach((row) => toggleRowSelected(row.id, true));
        }

    }, [selectedRows]);

    // Kun togglataan valintoja, passataan valinnat eteenpäin.
    useMountedLayoutEffect(() => {
        // Turha passata koko isoa objektipompsia kun kuitenkin halutaan vain tietää valitun rivin arvot.
        const rows = selectedFlatRows.map((row) => selectedRowKey ? row.original[selectedRowKey] : row.original);
        onSelect(rows);
    }, [onSelect, selectedFlatRows]);

    // Filttereitä kun muuttaa, niin tutkitaan onko valittu sivu mahdollinen, jos ei nin palauttaa ensimmäiselle sivulle
    // Tai jos useampi eri käyttää ja persistoitu state, niin saattaa jäädä pageIndex mahdottomaksi
    useEffect(() => {
        if (! pageOptions.includes(state.pageIndex)) {
            gotoPage(0);
        }
    }, [state.pageIndex, state.filters, pageOptions, gotoPage]);

    // useEffect(() => {
    //     if (isManual) {
    //         onFetchData({ pageSize, pageIndex });
    //     }
    // }, [pageIndex, pageSize, isManual, onFetchData]);

    if (data.length === 0 && !isManual) {
        // Ei oo dataa, mutta ladataa => näytetään spinneri
        if (isLoading) {
            return (
                <Placeholder type="table" columns={columns} rows={3} />
            );
        }
        return (
            <EmptyState
                message={emptyDataMessage}
                subText={emptyDataSubText}
                callToAction={emptyDataCallToAction}
                icon={emptyDataIcon}
            />
        );
    }

    const isPaginationVisible = (!isManual && data.length >= pageSizeOptions[0]) && hasPagination;

    const onColumnFilter = (e) => {
        const { value, checked } = e.target;
        toggleHideColumn(value, ! checked);
        if (checked) {
            setFilteredColumns(filteredColumns.filter((col) => col !== value));
        } else {
            setFilteredColumns([...filteredColumns, value]);
        }
    };

    const clearFilteredColumns = () => {
        const defaultHiddenColumns = memoizedColumns
            // miks suodatetaan semmoset pois mitkä ei ole näkyvillä, tässä kerätään piilotettavat saaraakkeet?
            //.filter((col) => (col.show ?? true))
            .map(resolveColumnIdentifier)
            .filter((colId) => defaultFilteredColumns.includes(colId))
            .filter(Boolean);
        setFilteredColumns(defaultHiddenColumns);
        setHiddenColumns(defaultHiddenColumns);
    };

    const onGetData = () => {
        // Otsikot yhteen läjään.
        // Filtteröidään pois ne sarakkeet joita ei exporttiin haluta (hideInExport & show = false).
        const headers = [].concat(
            memoizedColumns.filter((column) => (! column.hideInExport && (column?.show ?? true))).map((column) => column.Header),
            additionalExportColumns.map((column) => column.Header),
        );

        // Sarakkeet yhteen
        const columns = []
            .concat(memoizedColumns, additionalExportColumns)
            .filter((column) => (! column.hideInExport && (column?.show ?? true)));

        // Yhdistetään kaikki
        const rows = tableRows
            .filter(({ original: row }) => exportRowFilter ? exportRowFilter(row) : true)
            .map(({ original: row }) => (
                columns.reduce((allColumns, column) => {
                    const { accessor } = column;
                    // Otetaan arvo joko accessori-funkkarilta tai käytetään sitä pathina
                    const value = typeof accessor === 'function'
                        ? accessor(row)
                        : get(row, accessor);

                    // Mahdollinen kustomformaatteri jos on.
                    const exportFormatter = column.exportFormatter ?? ((value) => value);

                    return [].concat(
                        allColumns,
                        {
                            ...column,
                            value: exportFormatter(value),
                        }
                    );
                }, [])
            ));

        return { headers, rows, exportColumnTotals };
    };


    const handleTopScroll = () => {
        if (tableRef.current && topScrollbarRef.current) {
            tableRef.current.scrollLeft = topScrollbarRef.current.scrollLeft;
        }
    };

    const handleBottomScroll = () => {
        if (tableRef.current && topScrollbarRef.current) {
            topScrollbarRef.current.scrollLeft = tableRef.current.scrollLeft;
        }
    };

    const isActionBarVisible = (hasFilterableColumns && id) || isPaginationVisible;
    return (
        <Fragment>
            {isTotalVisible &&
                <div className="u-margin-bottom-small" >
                    {_transMd('**%filtered%** / **%preFiltered%** tulosta', {
                        filtered: filteredRows.length,
                        preFiltered: preFilteredRows.length
                    }, 'common')}
                    {(state.filters ?? []).length > 0 && (
                        <Button size="small" modifierClass="u-margin-left-small u-color-link u-font-weight-bold" onClick={() => setAllFilters([])} mdIcon="clear" ghost>
                            {state.filters.length > 1
                                ? _trans('Poista %filterCount% suodatinta', { filterCount: state.filters.length }, 'common')
                                : _trans('Poista suodatin', {}, 'common')}
                        </Button>
                    )}
                </div>
            }
            <div className={classNames('o-stack u-margin-bottom-small', {
                'o-stack--right': !canExport,
                'o-stack--justify': canExport
            })}>
                {canExport && (
                    <DataTableExport
                        fileName={exportFileName}
                        onGetData={onGetData}
                    />
                )}
                {isActionBarVisible && (
                    <ActionBar>
                        {(hasFilterableColumns && id) && (
                            <ColumnFilterList
                                columns={memoizedColumns}
                                clearFilteredColumns={clearFilteredColumns}
                                selectedColumns={filteredColumns}
                                onColumnFilter={onColumnFilter}
                            />
                        )}
                        {isPaginationVisible && (
                            <div>
                                <Pagination
                                    canNext={canNextPage}
                                    pageSize={state.pageSize}
                                    canPrevious={canPreviousPage}
                                    page={state.pageIndex}
                                    pages={pageCount}
                                    onPageChange={gotoPage}
                                    onPageSizeChange={setPageSize}
                                />
                            </div>
                        )}
                    </ActionBar>
                )}
            </div>
            <div className="scrollbar-top" style={{ maxWidth: `${topScrollbarContainer}px` }} ref={topScrollbarRef}
                onScroll={handleTopScroll}>
                <div className="scrollbar-top-content" style={{ width: `${topScrollBarContent}px`, height: '5px' }}
                    ref={topContentRef}></div>
            </div>
            <div ref={tableRef} onScroll={handleBottomScroll}
                className={classNames(`react-table react-table--${theme} u-margin-bottom-small`, {
                    // '-highlight': isHoverable,
                    '-sortable': isSortable,
                    '-resizable': isResizable,
                    '-not-filterable': !isFilterable,
                    '-selectable': isSelectable,
                    '-sticky': hasStickyColumns,
                }, stickyState)}>
                <div className="rt-table" {...getTableProps()}>
                    { hasVisibleHeaders &&
                    <div className="rt-thead">
                        {headerGroups.map((headerGroup, index) => (
                            <div key={index} {...headerGroup.getHeaderGroupProps()} className="rt-tr -header">
                                {headerGroup.headers.map((column, key) => {
                                    const canSort = column.canSort;
                                    const isResizing = column.isResizing ?? false;
                                    const isResizableColumn = isResizable && column.resizable !== false;
                                    const sortOrder = column.isSortedDesc ? 'descending' : 'ascending';
                                    const props = column.getHeaderProps(
                                        isResizableColumn
                                            ? (
                                                headerProps(
                                                    column.getSortByToggleProps({ title: _trans('Vaihda järjestys.', {}, 'common') }),
                                                    { column }
                                                )
                                            )
                                            : headerProps
                                    );
                                    return (
                                        <div
                                            key={key}
                                            {...props}
                                            className={classNames('rt-th', {
                                                'rt-resizable-header': isResizable && isResizableColumn,
                                                'rt-sortable-header': canSort,
                                                '-sorted': column.isSorted,
                                                'u-pointer-events-none u-user-select-none': isResizing,
                                            })}
                                            aria-sort={column.isSorted ? sortOrder : null}
                                        >
                                            <span
                                                className={classNames({
                                                    'rt-resizable-header-content': isResizable && isResizableColumn,
                                                    'rt-sortable-header-content': canSort,
                                                    'o-stack o-stack--justify': canSort,
                                                })}
                                            >
                                                {column.render('Header')}
                                                {column.isSorted && (
                                                    <MDIcon
                                                        icon={column.isSortedDesc ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                                        size="tiny"/>
                                                )}
                                            </span>
                                            {/* Use column.getResizerProps to hook up the events correctly */}
                                            {isResizableColumn && (
                                                <span
                                                    {...column.getResizerProps()}
                                                    className={classNames('rt-resizer', {
                                                        'is-resizing': isResizing,
                                                    })}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                        {isFilterable && headerGroups.map((headerGroup, index) => (
                            <div key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <div
                                        key={index}
                                        className="rt-th rt-resizable-header rt-tr -filters"
                                        {...column.getHeaderProps(headerProps)}
                                    >
                                        {column?.canFilter ? column.render('Filter') : null}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>}
                    <div {...getTableBodyProps()} className="rt-tbody">
                        {page.length === 0 && state.filters.length > 0 && (
                            <div className="u-text-center u-margin-top-small">
                                <div className="u-margin-bottom-small">
                                    {_trans('Valitsemillasi suodattimilla ei löytynyt yhtään tulosta. %count% tulosta suodatettu pois.', { count: preFilteredRows.length }, 'common')}
                                </div>
                                <div>
                                    <Button onClick={() => setAllFilters([])} mdIcon="clear">
                                        {_trans('Poista suodattimet', {}, 'common')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {page.map((row) => {
                            prepareRow(row);
                            const rowClassNames = getRowClassNames ? 'rt-tr ' + getRowClassNames(row.original) : 'rt-tr';

                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <div ref={rowRef} {...row.getRowProps()} className={rowClassNames}>
                                        {row.cells.map((cell, index) => {
                                            //console.log(cell.column.getColumnClassNames);
                                            const filterValue = cell.column?.filterValue;
                                            return (
                                                <div
                                                    key={index}
                                                    {...cell.getCellProps(cellProps)}
                                                    className={classNames('rt-resizable-column rt-td', {
                                                        [cell.column?.getColumnClassNames ? cell.column?.getColumnClassNames(row.original) : '']: typeof cell.column?.getColumnClassNames === 'function',
                                                    })}
                                                >
                                                    <div
                                                        className={classNames({
                                                            'u-text-wrap': cell.column?.hasWordWrap ?? false,
                                                            'u-text-truncate': cell.column?.isTextTruncated ?? true,
                                                        })}
                                                    >
                                                        {CellRenderer({ cell, row, filterValue })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {row.isExpanded && typeof renderSubComponent === 'function' ? (
                                        <div className={rowClassNames}>
                                            <div>
                                                {renderSubComponent({ row })}
                                            </div>
                                        </div>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
            {isPaginationVisible && (
                <div className="u-text-right">
                    <Pagination
                        canNext={canNextPage}
                        pageSize={state.pageSize}
                        canPrevious={canPreviousPage}
                        page={state.pageIndex}
                        pages={pageCount}
                        onPageChange={gotoPage}
                        onPageSizeChange={setPageSize}
                    />
                </div>
            )}
        </Fragment>
    );
};

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    defaultPageSize: PropTypes.number,
    defaultSortBy: PropTypes.array,
    defaultSelectedRows: PropTypes.object,
    isManual: PropTypes.bool,
    isLoading: PropTypes.bool,

    onFetchData: PropTypes.func,

    selectedRows: PropTypes.array,
    selectedRowKey: PropTypes.string,

    /**
     * Valittaessa rivi annetaan ulos valittujen rivien id:t.
     */
    onSelect: PropTypes.func,

    /**
     * Function for resolving whether the checkbox of the selectable row is disabled.
     * Has the row-object as parameter.
     */
    resolveIsCheckboxRowDisabled: PropTypes.func,

    /**
     * Ovatko rivit valittavia.
     */
    isSelectable: PropTypes.bool,

    /**
     * Onko jokainen sarake oletuksena järjesteltävä?
     */
    isSortable: PropTypes.bool,

    /**
     * Onko jokainen sarake oletuksena suodatettavana?
     */
    isFilterable: PropTypes.bool,

    /**
     * Onko jokaisen sarakkeen leveys määriteltävänä?
     */
    isResizable: PropTypes.bool,

    /**
     * Mikä viesti näytetään kun data on tyhjä.
     */
    emptyDataMessage: PropTypes.string,
    /**
     * Mikä alaviesti näytetään kun data on tyhjä.
     */
    emptyDataSubText: PropTypes.string,
    /**
     * Mikä ikoni näytetään kun data on tyhjä. Katso svgIconTypes.js
     */
    emptyDataIcon: PropTypes.oneOf(Object.values(svgIconTypes)),

    /**
     * Mahdollinen tyhjän tilan action-nappi
     */
    emptyDataCallToAction: PropTypes.node,

    /**
     * Pakinaatio, oletuksen true, disabloi jos tiedät ettet tarvi
     *
     * Huomaa: näyttäisi, että defaultPageSize on käytössä tämän asennosta huolimatta eli jos passaat tälle falsen,
     * niin varmista, että defaultPageSize-propsissa  on huomioitu mahdollinen suuri rivimäärä. Muuten voi käydä
     * niin, että osa dataTaabelille passatusta datasta jää piiloon mikäli defaultPageSize ylittyy.
     */
    hasPagination: PropTypes.bool,

    /**
     * Toimintosarake. Joko React node tai funkkari. Tälle passataan kaikki rivin propsit. Viimeisimpänä sarakkeista.
     */
    actionsColumn: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * Toimintosarakkeen vaihtoehtoinen otsikko.
     */
    actionsColumnHeader: PropTypes.string,
    actionsColumnWidth: PropTypes.number,
    /**
     * Näytetäänkö toimintosarake vaikka toimintoja annettu.
     */
    isActionsColumnVisible: PropTypes.bool,

    /**
     * Onko toimintosarake kiinnitetty (sticky) taulukon oikeaan laitaan.
     */
    isActionsColumnSticky: PropTypes.bool,
    /**
     * Käytössä oleva teema. Tällä hetkellä vain kaksi: default ja compact.
     */
    theme: PropTypes.oneOf(themes),
    /**
     * Jos haluat lisätä omia custom-classnameja per taabelin rivi, saa parametrina rivin datan
     */
    getRowClassNames: PropTypes.func,

    id: PropTypes.string,

    /**
     * Anna lisäksi tablelle id & kolumneille mitä haluat filteröidä id (esim. Header ei kelpaa, koska tarvitaan unique).
     */
    hasFilterableColumns: PropTypes.bool,

    hasPersistingTableState: PropTypes.bool,

    /**
     * Jos halutaan käyttää sub komponenttia, niin voi passaa funktio, joka renderöi
     * Triggeröi samalal käytetäänkö sub componenttia, typeof === function
     */
    renderSubComponent: PropTypes.func,

    /**
     * Näytetäänkö export-nappula.
     */
    canExport: PropTypes.bool,

    /**
     * Export-tiedoston nimi, ilman tiedostopäätettä.
     */
    exportFileName: PropTypes.string,

    /**
     * Optional filter for exported rows. Can be used in hiding certain rows.
     */
    exportRowFilter: PropTypes.func,

    /**
     * Jos halutaan yhteensä rivi lisätä pohjalle. Käytössä lähinnä vain pikarapsoilla.
     * Lisätään muodossa { key: total, sarake1: 2134, ... }, jossa key on sarakkeen id (tai accessor)
     */
    exportColumnTotals: PropTypes.object,

    /**
     *  Voi passata lisäsarakkeita exportille
     *  Tarkempi prop tsekkki DataTableCSVExport-komponentilla
     */
    additionalExportColumns: PropTypes.array,

    /**
     * Näytetäänkö x/y tulosta.
     */
    isTotalVisible: PropTypes.bool,

    /**
     * Näytetäänkö DataTablen headereitä
     */
    hasVisibleHeaders: PropTypes.bool
};

DataTable.defaultProps = {
    defaultPageSize: 20,
    defaultSortBy: [],
    defaultSelectedRows: {},
    hasPagination: true,
    isManual: false,
    isLoading: false,
    onFetchData() {},
    selectedRows: [],
    selectedRowKey: '',
    onSelect() {},
    resolveIsCheckboxRowDisabled() {},
    isSelectable: false,
    isSortable: true,
    isFilterable: true,
    isResizable: true,
    emptyDataMessage: _trans('Ei tuloksia.', {}, 'common'),
    emptyDataSubText: '',
    emptyDataIcon: 'inbox',
    emptyDataCallToAction: null,
    actionsColumn: null,
    actionsColumnHeader: _trans('Toiminnot', {}, 'common'),
    actionsColumnWidth: undefined,
    isActionsColumnVisible: true,
    isActionsColumnSticky: false,
    theme: 'default',
    getRowClassNames: null,
    id: '',
    hasFilterableColumns: false,
    hasPersistingTableState: false,
    renderSubComponent: null,
    canExport: false,
    exportFileName: undefined,
    exportRowFilter: undefined,
    exportColumnTotals: {},
    additionalExportColumns: [],
    isTotalVisible: true,
    hasVisibleHeaders: true,
};
