import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { Field } from 'formik';
import taxCardShape from 'shared/shapes/taxCard';
import { Feedback } from 'shared/components';

export const TaxCardOverrideVeroFetchField = ({ taxCard }) => {
    const dispatch = useDispatch();
    const isOverrideVeroFetch = useSelector(select.taxCards.isOverrideVeroFetch);
    const showOnlyText = ! useSelector(select.taxCards.isEditMode);
    const isVeroApiEnabled = useSelector(select.taxCards.isVeroApiEnabled);
    const selectedTaxCardId = useSelector(select.taxCards.getSelectedTaxCardId);
    const activeTaxCardId = useSelector(select.taxCards.getActiveTaxCardId);
    const selectedTaxCard = useSelector((state) => select.taxCards.getTaxCardById(state, selectedTaxCardId));

    const viewTaxCard = selectedTaxCard ?? taxCard;

    if (! isVeroApiEnabled || viewTaxCard?.isVeroFetched || showOnlyText || ! activeTaxCardId) {

        if (viewTaxCard.isOverrideVeroFetch && activeTaxCardId === viewTaxCard.taxCardId) {
            return (<div>
                {_trans('Syötän verokortin tiedot käsin', {}, 'extract')}: <b>{_trans('button.yes')}</b>
                <Feedback
                    type="error"
                    modifierClass="u-margin-bottom"
                >
                    {_trans('Verokortin voimassaolon aikana henkilölle ei haeta automattisesti verokorttia', {}, 'extract')}
                    <br/>
                    {_trans('Automaattinen verokortin haku kytkeytyy päälle painamalla Hae verokorttia-painiketta.', {}, 'extract')}
                </Feedback>
            </div>);
        }
        return (<div/>);
    }

    return (
        <div>
            <label>
                <Field
                    id="isOverrideVeroFetch"
                    name="isOverrideVeroFetch"
                    type="checkbox"
                    checked={isOverrideVeroFetch}
                    onChange={(event) => dispatch.taxCards.setIsOverrideVeroFetch(event.target.checked)}
                />
                {_trans('Syötän verokortin tiedot käsin', {}, 'extract')}
            </label>
            { isOverrideVeroFetch &&
                <Feedback
                    type="error"
                    message={_trans('Verokortin voimassaolon aikana henkilölle ei haeta automattisesti verokorttia', {}, 'extract')}
                    modifierClass="u-margin-bottom"
                />
            }
        </div>
    );
};

TaxCardOverrideVeroFetchField.propTypes = {
    taxCard: taxCardShape,
};

TaxCardOverrideVeroFetchField.defaultProps = {
    taxCard: {},
};
