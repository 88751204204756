import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import {
    PreviousEmployeeSignature
} from 'shared/ReForm/containers/CustomBlocks/ContractV3/components/Signatures/PreviousEmployeeSignature';
import Button from 'shared/components/Button';
import MDIcon from 'shared/components/MDIcon';
import { SummaryListItem } from 'shared/components/SummaryList/SummaryListItem';
import Switch from 'shared/components/Switch';
import { SignatureOnPaper } from 'shared/ReForm/components/SignatureOnPaper';

/**
 * Työntekijän allekirjoitus.
 * @returns {JSX.Element|string|null}
 * @constructor
 */
const SignaturesEmployee = () => {
    const dispatch = useDispatch();
    const [seenSignatures, setSeenSignatures] = useState([]);

    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);
    const isReSignatureNeeded = useSelector(select.contract.isReSignatureNeeded);

    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    const isSigning = useSelector((state) => state.loading.effects.contract.postSignature);
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isOperator = isCommune || isCompany;
    const isFullySigned = useSelector(select.contract.isFullySigned);
    const hasEmployerSigned = useSelector(select.contract.hasEmployerSigned);
    const hasCommuneUserSigned = useSelector(select.contract.hasCommuneUserSigned);
    const hasOperatorSigned = isAssignmentContract ? hasEmployerSigned : hasCommuneUserSigned;

    const jobContactDataId = useSelector(select.contract.getContractDataId);
    const userId = useSelector(select.userMetadata.getUserId)?.toString();
    const isEmployee = useSelector(select.userMetadata.isEmployee);
    const isContractProposal = useSelector(select.contract.isProposalContract);
    const isBuildMode = useSelector(select.contract.isBuildMode);
    const signatures = useSelector(select.contract.getEmployeeSignatures);
    const previousEmployeeSignatures = useSelector(select.contract.getPreviousEmployeeSignatures);
    const employeesNotUsingOima = useSelector(select.contract.isEmployeeNotUsingOima);

    const signatureCount = (signatures ?? []).length;

    // Ei vaadita uusia allekirjoituksia. Kerrotaan milloin viimeksi allekirjoitettu ja kenen toimesta.
    if (hasPreviousSignatures && isReSignatureNeeded === false) {
        return previousEmployeeSignatures.map((signature, index) => {
            const signerId = signature?.signerId?.toString();
            const isEmployeeNotUsingOima = (
                (signature?.verifiedByName
                    || employeesNotUsingOima.find((employeeUserId) => employeeUserId?.toString() === signerId)
                )
            );

            return (
                <SummaryListItem
                    term={isAssignmentContract
                        ? _trans('Hoitaja %signatureIndex%/%signatureCount%', {
                            signatureIndex: index + 1,
                            signatureCount,
                        }, 'jobContract')
                        : _trans('Työntekijä', {}, 'jobContract')}
                    key={index}
                >
                    <PreviousEmployeeSignature
                        signature={signature}
                        isEmployeeNotUsingOima={isEmployeeNotUsingOima}
                    />
                </SummaryListItem>
            );
        });
    }

    // Allekirjoitusnappi
    // eslint-disable-next-line react/prop-types
    const SignButton = ({ buttonText = _trans('Allekirjoita', {}, 'jobContract'), signatureContractDataId, isSigningOnBehalf = false }) => (
        <Button
            inProgress={isSigning}
            positive={! isBuildMode}
            mdIcon="check"
            disabled={isBuildMode}
            modifierClass="u-1/1 u-margin-top-small"
            onClick={() => {
                dispatch.contract.postSignature({
                    contractDataId: isSigningOnBehalf
                        ? signatureContractDataId
                        : jobContactDataId,
                    isSigningOnBehalf
                })
                    .then(({ data, status, message }) => {
                        if (status === 'ok') {
                            if (signatures.every((signature) => signature.isSigned)) {
                                window.scrollTo(0, 0);
                            }
                            dispatch.contract.setContractData(data);
                        } else {
                            // Joko virheviesti jos löytyy tai geneerinen käsienlevittelyviesti
                            dispatch.notifications.addError(message ?? _trans('Sopimusta ei voitu allekirjoittaa.', {}, 'jobContract'));
                        }
                    });
            }}
        >
            {buttonText}
        </Button>
    );

    return (
        <Fragment>
            {signatures.map((signature, index) => {
                const label = isAssignmentContract
                    ? _trans('Hoitaja %signatureIndex%/%signatureCount%', {
                        signatureIndex: index + 1,
                        signatureCount,
                    }, 'jobContract')
                    : _trans('Työntekijä', {}, 'jobContract');

                const isSigned = isReSignatureNeeded
                    ? (signature?.isSigned ?? false)
                    : hasPreviousSignatures;
                const signTime = signature?.signTime;
                const signerId = signature?.signerId?.toString();
                const hasSeenSignature = seenSignatures.includes(signerId);
                const hasEmail = signature?.signerDetails?.email.trim() !== '' ?? false;

                const isEmployeeNotUsingOima = (
                    signature?.verifiedByName
                    || employeesNotUsingOima.find((employeeUserId) => employeeUserId?.toString() === signerId)
                    || signature?.signerDetails?.email?.toString().trim() === ''
                );
                const canEmployeeSign = (! employeesNotUsingOima.includes(userId)) && userId === signerId;

                const isNotUsingOimaText = isAssignmentContract
                    ? _trans(`Hoitaja ei käytä Oimaa. Odottaa hyvinvointialueen edustajan allekirjoitusta.`, {
                        signatureCount,
                    }, 'jobContract')
                    : _trans(`Työntekijä ei käytä Oimaa. Odottaa hyvinvointialueen edustajan allekirjoitusta.`, {}, 'jobContract');

                if (isEmployee && ! canEmployeeSign && ! isSigned && isEmployeeNotUsingOima) {
                    return (
                        <SummaryListItem term={label} key={index}>
                            <strong className="u-display-block">
                                {signature?.signerDetails.fullName}
                            </strong>
                            <span className="u-muted">
                                {_trans('Odottaa allekirjoitusta paperiseen versioon.', {}, 'jobContract')}
                            </span>
                        </SummaryListItem>
                    );
                }
                return (
                    <SummaryListItem term={label} key={index}>
                        <Fragment>
                            {(isContractProposal && canEmployeeSign && ! isSigned) && (
                                <SignButton />
                            )}
                            {/* Allekirjoitus OK. */}

                            {isSigned && (
                                <div>
                                    <MDIcon icon="check_circle" size="small" modifierClass="u-color-positive u-align-middle u-margin-right-tiny" isFilled />
                                    <span className="u-align-middle">
                                        {isEmployeeNotUsingOima
                                            ? (
                                                <Fragment>
                                                    <strong>
                                                        {signature?.signerDetails.fullName}
                                                    </strong>{'. '}
                                                    {_trans('Allekirjoitus sopimuksen paperisessa versiossa.', {}, 'jobContract')}{' '}
                                                    {_transMd('%signer% vahvisti nähneensä paperisen sopimuksen **%signTime%** ja arkistoineensa sen.', {
                                                        signer: isEmployer
                                                            ? _trans('Hyvinvointialue', {}, 'common')
                                                            : signature?.verifiedByName ?? '-',
                                                        signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                                                    }, 'jobContract')}
                                                </Fragment>
                                            )
                                            : (
                                                _transMd(_trans('**%signer%** allekirjoitti **%signTime%**', {
                                                    signer: signature?.signerDetails.fullName ?? '-',
                                                    signTime: _toLocaleDate(signTime, '', _dateTimeFormat)
                                                }))
                                            )}
                                    </span>
                                </div>
                            )}

                            {/* Ehdotusta ei ole allekirjoitettu mutta TT pääsee Oimaan */}
                            {(! isSigned && isContractProposal) && (
                                <Fragment>
                                    {/* TT pääsee Oimaan */}
                                    {! isEmployeeNotUsingOima && (
                                        <div className="u-visibility-screen">
                                            {/*Näytetään nimi vain jos ei itse olla allekirjoittamassa*/}
                                            {! canEmployeeSign && (
                                                <Fragment>
                                                    <strong className="u-display-block">
                                                        {signature?.signerDetails.fullName}
                                                    </strong>
                                                    <span>{signature?.signerDetails.email}</span>
                                                </Fragment>
                                            )}
                                            {! canEmployeeSign && (
                                                <div className="u-muted">
                                                    {_trans('Odottaa allekirjoitusta.', {}, 'jobContract')}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* TT ei pääse Oimaan. TA ei ole allekirjoittanut. */}
                                    {(isEmployeeNotUsingOima && ! hasOperatorSigned) && (
                                        <Fragment>
                                            <div className="u-visibility-screen">
                                                <strong className="u-display-block">
                                                    {signature?.signerDetails.fullName}
                                                </strong>
                                                <div>
                                                    {hasEmail
                                                        ? isNotUsingOimaText
                                                        : _trans('Sähköpostiosoite puuttuu. Sopimus allekirjoitetaan paperiseen versioon. Odottaa hyvinvointialueen edustajan allekirjoitusta.', {}, 'jobContract')}
                                                </div>
                                            </div>
                                            {/*  <div className="u-visibility-print">
                                                {_trans('Odottaa sopimuksen osapuolen 1/2 allekirjoitusta.', {}, 'jobContract')}
                                            </div>*/}
                                        </Fragment>
                                    )}

                                    {/* TT ei pääse Oimaan. TA:lla on allekirjoittanut. */}
                                    {(isEmployeeNotUsingOima && hasOperatorSigned && ! isOperator) && (
                                        <div className="u-visibility-screen">
                                            <strong className="u-display-block">
                                                {signature?.signerDetails.fullName}
                                            </strong>
                                            <div className="u-margin-bottom-small">
                                                <p>
                                                    {isAssignmentContract
                                                        ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
                                                        : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract')}
                                                    {' '}{_trans('Odottaa allekirjoitusta paperiseen versioon.', {}, 'jobContract')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {(isEmployeeNotUsingOima && hasOperatorSigned && isOperator) && (
                                        <div className="u-visibility-screen">
                                            <strong className="u-display-block">
                                                {signature?.signerDetails.fullName}
                                            </strong>
                                            <div className="u-margin-bottom-small">
                                                <p>
                                                    {isAssignmentContract
                                                        ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
                                                        : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract')}
                                                </p>
                                                {` ${_trans('Tulosta sopimus ja pyydä siihen allekirjoitus. Nähtyäsi allekirjoituksen voit vahvistaa sopimuksen Oimassa.', {}, 'jobContract')}`}
                                            </div>
                                            <Switch
                                                id="hasSeenSignature"
                                                onChange={(isOn) => {
                                                    setSeenSignatures(isOn
                                                        ? seenSignatures.concat(signerId)
                                                        : seenSignatures.filter((userId) => userId !== signerId)
                                                    );
                                                }}
                                                modifierClass="u-margin-bottom-small"
                                            >
                                                {_trans('Olen nähnyt paperisen sopimuksen allekirjoituksen ja arkistoinut sen.', {}, 'jobContract')}
                                            </Switch>

                                            {hasSeenSignature && (
                                                <SignButton
                                                    signatureContractDataId={signature?.signerDetails?.contractDataId}
                                                    isSigningOnBehalf
                                                    buttonText={_trans('Vahvista', {}, 'common')}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Fragment>
                            )}
                            {/* Paikka, aika ja allekirjoitus. Vain tulosteissa / PDF. */}
                            {(isContractProposal && hasOperatorSigned && isEmployeeNotUsingOima && ! isFullySigned && isOperator) && (
                                <SignatureOnPaper fullName={signature?.signerDetails?.fullName} />
                            )}
                            {/* Tieto sopimuksen vahvistuksesta. */}
                            {(! isSigned && ! isContractProposal) && (
                                <Fragment>
                                    {isEmployeeNotUsingOima && (
                                        <div>
                                            <strong>{signature?.signerDetails.fullName}</strong><br/>
                                            <span>{signature?.signerDetails.email}</span>
                                            <div>
                                                {isAssignmentContract
                                                    ? _trans('Hoitaja ei käytä Oimaa.', {}, 'jobContract')
                                                    : _trans('Työntekijä ei käytä Oimaa.', {}, 'jobContract')}
                                                {' '}{_trans('Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}
                                            </div>
                                        </div>
                                    )}
                                    {! isEmployeeNotUsingOima && (
                                        <div>
                                            <strong>{signature?.signerDetails.fullName}</strong><br/>
                                            <span>{signature?.signerDetails.email}</span>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    </SummaryListItem>
                );
            })}
        </Fragment>
    );
};


/*=======
    {hasEmail
        ? _transObj(translations.isNotUsingOima ?? {})
        : _trans('Sähköpostiosoite puuttuu.', {}, 'jobContract')}
{' '}{_trans('Allekirjoitus tehdään sopimuksen paperiseen versioon.', {}, 'jobContract')}
>>>>>>> origin/master*/

export default SignaturesEmployee;
