export const blocks = {
    // Ryhmittelypalikat
    FIELDSET: 'fieldset',
    GROUP: 'group',

    // Palautepalikat
    FEEDBACK: 'feedback',

    // blocks-listan renderöinti useampaan kertaan (vrt. useampi palkkio).
    ARRAY: 'array',

    // Käyttäjän syötettä odottavat palikat.
    INPUT: 'input',

    // Meidän itse tekemät. Nämä voi olla käytännössä mitä vain mutta
    // näiden tulos ei mene bäkkärille (yleensä).
    COMPONENT: 'component',

    // Pelkkä teksti (markdown)
    TEXT: 'text',

    // Viiva
    SEPARATOR: 'separator',

    // Break fater. Vain tulosteisiin
    PAGE_BREAK_AFTER: 'pageBreakAfter',
};

export const isArrayBlock = (block) => blocks.ARRAY === block;
export const isGroupBlock = (block) => [blocks.FIELDSET, blocks.GROUP, blocks.ARRAY].includes(block);

export const groupBlocks = [
    blocks.GROUP,
    blocks.FIELDSET,
];


