import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import TableSalaryChoices from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/TableSalaryChoices';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { resolveChoiceChainOptions } from 'ContractV3/utils/resolveChoiceChainOptions';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';
import { ERROR_NOT_FOUND_FOR_DATE } from 'shared/constants/tableSalary';

/**
 * Taulukkopalkka.
 * @param arrayName
 * @param name
 * @param originalName
 * @returns {JSX.Element}
 * @constructor
 */
const TableSalaryAttributes = ({ arrayName, name, originalName }) => {
    const dispatch = useDispatch();
    // Taulukkopalkan arvot (salary.salaries.NN.tableSalaryAttributes)
    const [field] = useField(name);
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const [,,isPendingSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_SALARY));
    const hasSalaryRelatedComponentsVisible = useSelector(select.contract.hasSalaryRelatedComponentsVisible);
    const collectiveAgreement = useSelector(select.contract.getCollectiveAgreement);
    const benefitDecisionId = useSelector(select.contract.getBenefitDecisionId);
    const tableSalaryDate = useSelector(select.contract.getTableSalaryDate);

    // Salaarityyppi
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = salaryTypeField.value;

    // Initialsalary
    const [,,initialSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.INITIAL_SALARY));

    //Vähimmäispalkka
    const [,,minimumRequiredTableSalaryHelpers] = useField(resolveArrayName(arrayName, fieldNames.MINIMUM_REQUIRED_TABLE_SALARY));
    const hasMinimumTableSalary = useSelector(select.tableSalary.isMinimumTableSalaries);

    // Valittu työtehtävä
    const [jobTitleField] = useField(fieldNames.JOB_TITLE);
    const jobTitle = jobTitleField.value;
    // Työntekijän työkokemus

    const employeeWorkExperience = useSelector((state) =>
        select.employeeWorkExperience.getEmployeeWorkExperienceWithJobTitle(state, jobTitle, benefitDecisionId ? collectiveAgreement : null)
    );
    // Työalue (jos on)
    const area = useSelector(select.contract.getArea);
    const metadata = useSelector(select.tableSalary.getMetadata);

    const isUsingYearsWhenResolvingTableSalary = useSelector(select.tableSalary.isUsingYearsWhenResolvingTableSalary);
    const workExperienceValueType = isUsingYearsWhenResolvingTableSalary
        ? 'workExperienceYears'
        : 'workExperienceMonths';

    const workExperienceValue = employeeWorkExperience?.[workExperienceValueType] ?? 0;

    // Päivitetään valinnat vain jos jokin riippuvuus muuttuu
    const options = useMemo(() => {
        const values = field.value || {};
        return (
            metadata.map((option) => ({
                ...option,
                label: _transObj(option.label),
                options: resolveChoiceChainOptions(option, { values, salaryType, area, workExperienceValue, tableSalaryDate })
            }))
        );
    }, [metadata, field.value, salaryType, area, workExperienceValue, tableSalaryDate]);

    // Eläpä näyttä jos ei liksoja käytössä
    if (! isTableSalaryInUse || ! hasSalaryRelatedComponentsVisible) return null;

    return (
        <TableSalaryChoices
            arrayName={arrayName}
            options={options}
            name={name}
            originalName={originalName}
            onChange={(values) => {
                // Haetaan taulukkopalkat
                dispatch.contract.fetchTableSalary(values).then((salary) => {
                    if (salary) {
                        if (salary === ERROR_NOT_FOUND_FOR_DATE) {
                            isPendingSalaryHelpers.setValue(true);
                            initialSalaryHelpers.setValue('0.00');

                            return;
                        }
                        isPendingSalaryHelpers.setValue(false);
                        if (! hasMinimumTableSalary) {
                            initialSalaryHelpers.setValue((parseFloat(salary).toFixed(2)));
                        }
                        minimumRequiredTableSalaryHelpers.setValue((parseFloat(salary).toFixed(2)));
                    } else {
                        initialSalaryHelpers.setValue(null);
                        dispatch.notifications.addWarning(
                            _trans('Taulukkopalkkaa ei löytynyt päivämäärällä %date%. Vaihda päivämäärä.', {
                                date: values?.date ? _toLocaleDate(values?.date) : '-'
                            }, 'common')
                        );
                    }
                });
            }}
        />
    );
};

TableSalaryAttributes.propTypes = {
    arrayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
};

export default TableSalaryAttributes;

