import api from 'api';
import { salesInvoiceStates } from 'InvoicingTool/constants/SalesInvoiceStates';
import { createQueryParams } from 'shared/utils/commonUtils';

export const invoices = {
    state: {
        invoices: [],
        openInvoices: [],
        waitingInvoices: [],
        invoice: null,
        sentMaterials: [],
        hasSftpCustomerFolder: false,
    },
    reducers: {
        setInvoices: (state, invoices) => ({ ...state, invoices }),
        setOpenInvoices: (state, openInvoices) => ({ ...state, openInvoices }),
        setWaitingInvoices: (state, waitingInvoices) => ({ ...state, waitingInvoices }),
        setInvoice: (state, invoice) => ({ ...state, invoice }),
        setSentMaterials: (state, sentMaterials) => ({ ...state, sentMaterials }),
        setHasSftpCustomerFolder: (state, hasSftpCustomerFolder) => ({ ...state, hasSftpCustomerFolder }),
    },
    selectors: {
        getInvoices: (state) => state.invoices,
        getOpenInvoices: (state) => state.openInvoices,
        getWaitingInvoices: (state) => state.waitingInvoices,
        getInvoice: (state) => state.invoice,
        getSentMaterials: (state) => state.sentMaterials,
        hasSftpCustomerFolder: (state) => state.hasSftpCustomerFolder,
    },
    effects: (dispatch) => ({
        async fetchSentSalesInvoices(dateRange){
            try {
                const queryParams = createQueryParams({
                    startDate: dateRange.start,
                    endDate: dateRange.end,
                    state: salesInvoiceStates.SENT,
                });
                const json = await api.get('/api/v3/sales-invoices?' + queryParams);
                this.setInvoices(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async fetchOpenSalesInvoices(){
            try {
                const json = await api.get(`/api/v3/sales-invoices?state=${salesInvoiceStates.OPEN}`);
                this.setOpenInvoices(json.data ?? []);
                this.setHasSftpCustomerFolder(json.meta.hasSftpCustomerFolder ?? false);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },

        async fetchWaitingForExecutionSalesInvoiceMaterials() {
            try {
                const json = await api.get(`/api/v3/sales-invoice-materials?state=1`);
                this.setWaitingInvoices(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async fetchSentSalesInvoiceMaterials() {
            try {
                const json = await api.get(`/api/v3/sales-invoice-materials?state=2`);
                this.setSentMaterials(json.data ?? []);
                return json;
            } catch (e) {
                return { status: 'error' };
            }
        },
        async postSetReadyForMaterialCreation(model){
            try {
                return await api.post('/api/v3/sales-invoice/set-ready-for-material-sending', model);
            } catch (e) {
                dispatch.notifications.addError(_trans('Laskujen tallennuksen aikana tapahtui virhe.', {}, 'invoicingTool'));
                return { status: 'error' };
            }
        },
        async fetchInvoice(id) {
            try {
                const json = await api.get(`/api/v3/sales-invoice/${id}/details`);
                this.setInvoice(json.data ?? null);
                return json;
            } catch (e) {
                dispatch.notifications.addError(_trans('Laskun tietojen haussa tapahtui virhe.', {}, 'invoicingTool'));
                return { status: 'error' };
            }
        },

        async postCreditInvoice(id){
            try {
                const json = await api.post(`/api/v3/sales-invoices/${id}/create-credit-invoice`);
                this.setInvoice(json.data ?? null);
                dispatch.notifications.addSuccess(_trans('Hyvityslasku luotu', {}, 'invoicingTool'));
                return json;
            } catch (e) {
                dispatch.notifications.addError(_trans('Hyvityslasku luominen epäonnistui', {}, 'invoicingTool'));
                return { status: 'error' };
            }
        },

        async postCreditAndCreateNew(invoice) {
            try {
                const json = await api.post(`/api/v3/sales-invoices/${invoice.id}/credit-and-create-new`, invoice);
                if (json.status === 'ok') {
                    dispatch.notifications.addSuccess(_trans('Lasku hyvitetty ja luotu uusi muokatuilla arvoilla.', {}, 'invoicingTool'));
                }
                return json;
            } catch (e) {
                dispatch.notifications.addError(_trans('Laskun muokkaamisessa tapahtui virhe.', {}, 'invoicingTool'));
                return { status: 'error' };
            }
        },

        async postNewInvoice(invoice) {
            try {
                const json = await api.post('/api/v3/sales-invoices', invoice);
                return json;
            } catch (e) {
                dispatch.notifications.addError(_trans('Laskun luomisessa tapahtui virhe.', {}, 'invoicingTool'));
                return { status: 'error' };
            }
        },
        async deleteOpenSalesInvoice(salesInvoice) {
            try {
                return await api.del(`/api/v3/sales-invoices/${salesInvoice}`);
            } catch (e) {
                dispatch.notifications.addError(_trans('Laskun poistamisessa tapahtui virhe.', {}, 'invoicingTool'));
                return { status: 'error' };

            }
        },
        async putSalesInvoice(invoice){
            try {
                const json = await api.put(`/api/v3/sales-invoices/${invoice.id}`, invoice);
                if (json.status === 'ok') {
                    this.setInvoice(json.data ?? null);
                    dispatch.notifications.addSuccess(_trans('Laskun muutokset tallennettu.', {}, 'invoicingTool'));
                }
                return json;
            } catch (e) {
                dispatch.notifications.addError(_trans('Laskun muokkaamisessa tapahtui virhe.', {}, 'invoicingTool'));
                return { status: 'error' };
            }
        }
    })
};
