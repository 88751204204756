import { Panel, SummaryList } from 'shared/components';

export const UserAddress = (user) => (
    <Panel
        heading={_trans('Käyttäjälle tallennetut osoitetiedot', {}, 'additionalAddress')}
    >
        <SummaryList list={[
            {
                term: _trans('Osoite', {}, 'additionalAddress'),
                description: user.user.streetAddress,
            },
            {
                term: _trans('Postinumero', {}, 'additionalAddress'),
                description: user.user.postCode,
            },
            {
                term: _trans('Postitoimipaikka', {}, 'additionalAddress'),
                description: user.user.town,
            }
        ]} />
    </Panel>
);
