import _ from 'lodash';
import { fromJS, List, Map } from 'immutable';
import { promised as request } from 'BubbleWrapAgent';
import userRoles from 'shared/constants/userRoles';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';

const initialState = fromJS({
    isAdmin: false,
    isCommune: false,
    isCommuneAdmin: false,
    isCommuneSuperUser: false,
    communeId: null,
    hasError: false,
    hasMetadataFetched: false,
    canAuthorizeUsers: false,
    useDimensions: false,
    hasExternalServiceCharges: false,
    allowAssignmentContracts: false,
    hasOwnBankAccount: false,
    isTenantEnabled: false,
    userStateOfAffairs: {},
});

/**
 * Käyttäjän metadatatiedot. Käytetään Rematchin kanssa.
 */
export default {
    state: initialState,

    reducers: {
        set: (state, payload) => fromJS(payload),
    },

    selectors: {
        getUser: (state) => {
            const firstName = state.get('firstName', '-');
            const lastName = state.get('lastName', '-');
            return {
                userId: state.get('userId', '-'),
                firstName,
                lastName,
                fullName: `${lastName} ${firstName}`,
                socialSecurityNumber: state.get('socialSecurityNumber', '-'),
                email: state.get('email', '-'),
            };
        },
        isAdmin: (state) => state.get('isAdmin', false),
        isPreviousAdmin: (state) => state.get('isPreviousAdmin', false),
        isCommune: (state) => state.get('isCommune', false),
        isCommuneAdmin: (state) => state.get('isCommuneAdmin', false),
        isAnyAdmin: (state) => (
            state.get('isAdmin', false)
            || state.get('isPreviousAdmin', false)
            || state.get('isCommuneAdmin', false)
        ),
        isCommuneSuperUser: (state) => state.get('isCommuneSuperUser', false),
        hasCommuneGroup: (state) => state.get('hasCommuneGroup', false),
        getCommuneId: (state) => state.get('communeId', null),
        getMetadata: (state) => state,
        hasMetadataFetched: (state) => state.get('hasMetadataFetched'),
        isOperator: (state) => [userRoles.COMPANY_OPERATOR, userRoles.COMMUNE_OPERATOR].includes(state.get('userRole')),
        isCompany: (state) => state.get('userRole') === userRoles.COMPANY_OPERATOR,
        isEmployer: (state) => state.get('userRole') === userRoles.EMPLOYER,
        isEmployee: (state) => [userRoles.EMPLOYEE, userRoles.FAMILY_CARER].includes(state.get('userRole')),
        isPrivate: (state) => state.get('isPrivate', false),
        isIdentified: (state) => state.get('isIdentified', false),
        getUserRole: (state) => state.get('userRole'),
        getUserId: (state) => state.get('userId'),
        getCompanyName: (state) => state.get('companyName'),
        getCompanyShadowUserId: (state) => state.get('companyShadowUserId'),
        getEmail: (state) => state.get('email', ''),
        hasAccessToDimensions: (state) => state.get('useDimensions'),
        isDimensionPricingEnabled: (state) => state.get('enableDimensionPricing', false),
        hasExternalServiceCharges: (state) => state.get('hasExternalServiceCharges', false),
        getPayPeriodRoute: (state) => (
            state.get('userRole') === userRoles.COMMUNE_OPERATOR
                ? 'suoratyo_commune_timeregistration_show'
                : 'suoratyo_timeregistration_open'
        ),
        allowAssignmentContracts: (state) => state.get('allowAssignmentContracts', false),
        isMonthlySalaryCustomerInvoicingEnabled: (state) => state.get('isMonthlySalaryCustomerInvoicingEnabled', false),
        isMaventaEPayslipEnabled: (state) => state.get('isMaventaEPayslipEnabled', false),
        getEndpoints: (state) => state.get('endpoints', Map()),
        getCommuneName: (state) => state.get('communeName', ''),
        hasOwnBankAccount: (state) => state.get('hasOwnAccount', false),

        // Steppien tarkisteluun esim. voidaanko vakuutuksia vielä ottaa.
        getUserStateOfAffairSteps: (state) => state.getIn(['userStateOfAffairs', 'steps'], Map({})),
        isIdentificationComplete: (state) => state.getIn(['userStateOfAffairs', 'steps', 'isIdentificationComplete'], false),
        isUserDataComplete: (state) => state.getIn(['userStateOfAffairs', 'steps', 'isUserDataComplete'], false),
        hasYelEmployees: (state) => state.getIn(['userStateOfAffairs', 'steps', 'hasYelEmployees'], false),
        isInsurancesComplete: (state) => state.getIn(['userStateOfAffairs', 'steps', 'isInsurancesComplete'], false),
        isEverythingComplete: (state) => state.getIn(['userStateOfAffairs', 'steps', 'isEverythingComplete'], false),
        getUserStateOfAffairs: (state) => state.get('userStateOfAffairs'),
        getFeatureFlag: (state, key) => state.getIn(['exposedFeatureFlags', key], false),
        isRestrictedUser: (state) => state.get('isRestrictedUser', false),
        hasRestrictedFeatures: (state) => state.get('hasRestrictedFeatures', false),
        canCreateContracts: (state) => state.getIn(['userStateOfAffairs', 'canCreateContracts'], false),
        // Voiko asettaa allekirjoittajia
        canCreateSignedContracts: (state) => state.get('canCreateSignedContracts', false),
        // Voiko asettaa allekirjoittajia
        canSetContractSigners: (state) => state.get('canSetContractSigners', false),

        // HA-kohtaiset
        isBenefitDecisionMandatesEnabled: (state) => state.get('isBenefitDecisionMandatesEnabled', false),
        isVismaSignRequiredAuthorization: (state) => state.get('isVismaSignRequiredAuthorization', false),

        hasCustomerInvoicingArticleIdentifiers: (state) => state.get('hasCustomerInvoicingArticleIdentifiers', false),

        /**
         * Jos käyttäjällä on prokura tai pääkäyttäjärooli voi hän valtuuttaa muita käyttäjiä.
         * @param state
         * @returns {boolean}
         */
        canAuthorizeUsers: (state) => {
            const companyUserRelations = state.get('companyUserRelations', List([]));
            const relations = companyUserRelations.filter(
                (relation) => _.includes([
                    companyUserRelationTypes.RELATION_MASTER_USER,
                    companyUserRelationTypes.RELATION_PROCURIST,
                ], relation)
            );
            return relations.size > 0;
        },

        /**
         * Onko tunnistautuminen vanhentunut. Vanhenee vain kotitalouksilla.
         * @param state
         * @returns {*}
         */
        isIdentifierExpired: (state) => (
            // const isPrivate = state.get('isPrivate', false);
            state.getIn(['userStateOfAffairs', 'identification', 'isIdentifierExpired'], false)
        ),

        // Onko metatietojen lataus vielä kesken
        isLoading: (state) => _.get(state, 'loading.models.userMetadata', false),
        isNoTyELTransfer: (state) => !! state.get('isNoTyELTransfer', false),
        isVarmaPartner: (state) => !! state.get('isVarmaPartner', false),
        isVarmaPartnerPilot: (state) => !! state.get('isVarmaPartnerPilot', false),
        isVtjEnabled: (state) => state.get('isVtjEnabled', false),
        isDocumentToolEnabled: (state) => state.get('isDocumentToolEnabled', false),
        isReportingFormsEnabled: (state) => state.get('isReportingFormsEnabled', false),
        isAgeBasedCostReimbursementsMonthAdvance: (state) => state.get('isAgeBasedCostReimbursementsMonthAdvance', false),
        isTenantEnabled: (state) => state.get('isTenantEnabled', false),
    },

    effects: () => ({
        fetchUserMetadata({ fetchAffairs = false, fetchEndPoints = false, forceFetch = false } = {}, rootState) {
            if (!rootState.userMetadata.get('hasMetadataFetched', false) || forceFetch) {
                return request
                    .get(Routing.generate(
                        'api_1_get_user_metadata',
                        {
                            affairs: fetchAffairs ? 1 : 0,
                            endpoints: fetchEndPoints ? 1 : 0
                        }
                    ))
                    .then((response, error) => {
                        const userMetadata = _.get(response, 'body', null);
                        if (error || userMetadata === null) {
                            this.set({ hasError: true });
                            return Promise.reject();
                        } else {
                            const data = Object.assign({}, userMetadata, { hasMetadataFetched: true });
                            this.set(data);
                            return data;
                        }
                    });
            }

            return rootState.userMetadata.toJS();
        }
    })
};

export const loadingSelectors = {
    // Onko metatietojen lataus käynnissä
    isLoadingMetaData: (state) => _.get(state, 'loading.effects.userMetadata.fetchUserMetadata', false),
};
