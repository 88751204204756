import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { DateInputField, FormField } from 'shared/components/Formik';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import Feedback from 'shared/components/Feedback';
import { getFieldProps } from 'shared/ReForm/utils/getFieldProps';
import { isValidDate } from 'shared/utils/dateUtils';
import { HelpText } from 'shared/components';
import { format, subYears } from 'date-fns';

/**
 * Muutosten voimaantulopäivä.
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const DataStartDate = (props) => {
    const { name, placeholder } = props;
    const [earliestDataStartDateField] = useField(fieldNames.EARLIEST_DATA_START_DATE);
    const isBuildMode = useSelector(select.contract.isBuildMode);

    // Jos ei ole hyväksytty soppari, ei kysellä
    const isAcceptedContract = useSelector(select.contract.isAcceptedContract);
    const isAssignmentContract = useSelector(select.contract.isAssignmentContract);
    if (! isAcceptedContract) return null;

    // Tarkistetaan että on oikeasti Date-objekti ennen kuin lisätään mitään
    const earliestDataStartDate = new Date(earliestDataStartDateField.value);
    const minimumDate = earliestDataStartDateField.value && isValidDate(earliestDataStartDate)
        ? earliestDataStartDate
        : null;

    return (
        <FormField {...getFieldProps(props)} name={name}>
            <DateInputField
                placeholder={placeholder}
                minimumDate={minimumDate}
            />
            {isBuildMode && (
                <Feedback
                    type="tip"
                    size="tiny"
                    message={_trans('Näkyvillä vain kun sopimusta muokataan.', {}, 'contractBuilder')}
                    modifierClass="u-margin-top-small"
                />
            )}
            {/* Näytetään helppeteksti kesäkuun loppuun asti (0 = tammikuu) */}
            {isAssignmentContract && new Date().getMonth() <= 5 && (
                <HelpText
                    id={name}
                    text={(
                        <div>
                            {_trans('contract.form.duration.contract_data_start_date.help')}<br/>
                            <strong>
                                HUOM! Mikäli olet asettamassa sopimuksen muutoksen voimaan vuoden {format(subYears(new Date(), 1), 'yyyy')} puolelle, niin lue&nbsp;
                                <a target="_blank" href="https://ophtuki.oima.fi/support/solutions/articles/101000513700-sopimuksen-muokkaaminen-muutosten-voimaantulop%C3%A4iv%C3%A4-viime-vuoden-puolella-" rel="noreferrer">tämä ohje</a>, jotta palkkio muodostuu oikein myös vuodelle {format(new Date(), 'yyyy')}.
                            </strong>
                        </div>
                    )}
                />
            )}
        </FormField>
    );
};

DataStartDate.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default DataStartDate;
