import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { DataTable } from 'shared/containers/DataTable7';
import { groupBy } from 'shared/utils/collectionHelpers';
import Button from 'shared/components/Button';
import { List, ListItem } from 'shared/components/List';
import Badge from 'shared/components/Badge';
import MDIcon from 'shared/components/MDIcon';
import {
    getReportingFormBadgeTypeByState,
    reportingFormStates,
    translateReportingFormState
} from 'shared/ReportingForms/constants/reportingFormStates';
import EmptyState from 'shared/components/EmptyState';

export const ReportingFormsList = hot(({ onSelectReportingForm, reportingFormGroup, onSelectReportingFormGroup }) => {
    const reportingForms = useSelector(select.reportingForms.getReportingForms);
    const isDeletingReportingForm = useSelector((state) => state.loading.effects.reportingForms.deleteReportingForm);
    const isLoadingReportingForms = useSelector((state) => state.loading.effects.reportingForms.fetchReportingForms);

    const groupedReportingForms = useMemo(() => (
        groupBy(reportingForms, (reportingForm) => _transObj(reportingForm.template?.name))
    ), [reportingForms]);

    if (! reportingFormGroup) {
        if (reportingForms.length === 0) {
            return <EmptyState message={_trans('Ei lomakkeita', {}, 'reporting_forms')} />;
        }

        return (
            <List>
                {Object.entries(groupedReportingForms).map(([groupName, reportingForms], key) => {
                    const unconfirmedReportingForms = reportingForms.filter(({ state }) => ! [reportingFormStates.CONFIRMED].includes(state));
                    return (
                        <ListItem.Link key={key} onClick={() => onSelectReportingFormGroup(groupName)}>
                            <ListItem.PrimaryContent>
                                {unconfirmedReportingForms.length > 0
                                    ? (<b>{groupName}</b>)
                                    : groupName}
                            </ListItem.PrimaryContent>
                            <ListItem.SecondaryContent>
                                {unconfirmedReportingForms.length > 0 && (
                                    <Badge value={unconfirmedReportingForms.length} type="neutral" />
                                )}
                                <MDIcon icon="arrow_forward" />
                            </ListItem.SecondaryContent>
                        </ListItem.Link>
                    );
                })}
            </List>
        );
    }

    return (
        <Fragment>
            <Button flat mdIcon="arrow_backward" onClick={() => onSelectReportingFormGroup(null)} modifierClass="u-margin-bottom">
                {_trans('Takaisin', {}, 'common')}
            </Button>
            <h2 className="c-heading-subheading">
                {reportingFormGroup}
            </h2>
            <DataTable
                isTotalVisible={false}
                data={groupedReportingForms[reportingFormGroup]}
                columns={[
                    {
                        Header: _trans('Luotu', {}, 'common'),
                        accessor: 'created',
                        /* eslint-disable-next-line */
                        Cell: ({ value, row: { original: { id }} }) => {
                            return (
                                /* eslint-disable-next-line */
                                <button className="u-color-link" onClick={() => onSelectReportingForm(id)}>
                                    {_toLocaleDate(value, '', _dateTimeFormat)}
                                </button>
                            );
                        }
                    },
                    {
                        Header: _trans('Lomakkeen täyttäjä', {}, 'reporting_forms'),
                        accessor: 'authorUserFullName',
                    },
                    {
                        Header: _trans('Kirjauksen selite/nimi', {}, 'reporting_forms'),
                        accessor: 'description',
                    },
                    {
                        Header: _trans('Vahvistettu', {}, 'common'),
                        accessor: 'confirmed',
                        /* eslint-disable-next-line */
                        Cell: ({ value }) => (
                            _toLocaleDate(value, '-', _dateTimeFormat)
                        ),
                    },
                    {
                        Header: _trans('Tila', {}, 'common'),
                        accessor: 'state',
                        /* eslint-disable-next-line */
                        Cell: ({ value }) => (
                            <Badge
                                type={getReportingFormBadgeTypeByState(value)}
                                value={translateReportingFormState(value)}
                            />
                        ),
                        width: 120,
                    },
                ]}
                defaultSortBy={[
                    {
                        id: 'created',
                        desc: true
                    }
                ]}
                isLoading={isLoadingReportingForms || isDeletingReportingForm}
            />
        </Fragment>
    );
});

ReportingFormsList.propTypes = {
    onSelectReportingForm: PropTypes.func.isRequired,
    reportingFormGroup: PropTypes.string.isRequired,
    onSelectReportingFormGroup: PropTypes.func.isRequired,
};

