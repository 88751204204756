import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MDIcon from 'shared/components/MDIcon';
import { componentSizes } from 'shared/constants';

/**
 * Näyttää ikonin tekstin vieressä. Teksti voidaan antaa joko propsina tai sisältönä.
 * @param text
 * @param mdIcon
 * @param iconSize
 * @param isIconAfterText
 * @param title
 * @param modifierClass
 * @param iconModifierClass
 * @param textModifierClass
 * @param isIconFilled
 * @returns {*}
 * @constructor
 */
function IconText({
    text,
    mdIcon,
    iconSize,
    isIconAfterText,
    title,
    modifierClass,
    iconModifierClass,
    textModifierClass,
    isIconFilled,
}) {
    // Jos teksti ei ole string-tyyppiä ei lisätä turhia wrappereita.
    const isNode = (typeof text !== 'string') && (typeof text !== 'number');

    const hasText = text && (!isNode && text !== '');
    const content = isNode
        ? text
        : <span className={classNames('o-flag__body', textModifierClass)}>{text}</span>;

    const iconClass = classNames(iconModifierClass, {
        'o-flag__img': !isNode,
        'u-margin-left-tiny': isIconAfterText,
        'u-margin-right-tiny': !isIconAfterText,
    });

    const iconTextClass = classNames(modifierClass, {
        'o-flag o-flag--tiny': ! isNode,
    });

    return (
        <span className={iconTextClass}>
            {(isIconAfterText && hasText) && content}
            <MDIcon
                icon={mdIcon}
                size={iconSize}
                modifierClass={iconClass}
                title={title}
                isFilled={isIconFilled}
            />
            {(!isIconAfterText && hasText) && content}
        </span>
    );
}

IconText.defaultProps = {
    text: '',
    iconSize: componentSizes.LARGE,
    isIconAfterText: false,
    modifierClass: '',
    iconModifierClass: '',
    textModifierClass: '',
    title: '',
    isIconFilled: false,
};

IconText.propTypes = {
    /**
     * Teksti / sisältö ikonin yhteydessä.
     */
    text: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),

    /**
     * Ikonityyppi.
     */
    mdIcon: PropTypes.string.isRequired,

    /**
     * Ikonin koko.
     */
    iconSize: PropTypes.oneOf(Object.values(componentSizes)),

    /**
     * Näytetäänkö ikoni tekstin jälkeen.
     */
    isIconAfterText: PropTypes.bool,

    /**
     * Title-attribuutti kun hiiri viedään elementin päälle.
     */
    title: PropTypes.string,

    /**
     * CSS-muokkausluokka.
     */
    modifierClass: PropTypes.string,

    /**
     * Ikonin CSS-muokkausluokka.
     */
    iconModifierClass: PropTypes.string,

    /**
     * Tekstin CSS-muokkausluokka.
     */
    textModifierClass: PropTypes.string,

    isIconFilled: PropTypes.bool,
};

export default IconText;
