import produce from 'immer';
import api from 'api';

/**
 * Viestiketju, jossa lukemattomia viestejä
 */
export const INSTANT_MESSAGE_THREAD_STATE_UNREAD = 'unread';

export const INSTANT_MESSAGE_THREAD_STATE_READ = 'read';

export const INSTANT_MESSAGE_THREAD_STATE_REPLIED = 'replied';

export const INSTANT_MESSAGE_THREAD_STATE_ARCHIVED = 'archived';

// Pikaviestit
export const instantMessages = {
    state: {
        threadMetadata: {
            'ownThreadCount': 0,
            'hyveThreadCount': 0,
            'archivedThreadCount': 0,
        },
        threadRecipients: [],
        selectableDimensions: [],
        messageThread: null,
        messageThreads: [],
        replyMessage: null,
    },

    reducers: {
        setThreadMetadata: (state, metadata) => ({ ...state, threadMetadata: metadata }),
        setSelectableDimensions: (state, dimensions) => ({ ...state, selectableDimensions: dimensions }),
        setThreadRecipients: (state, threadRecipients) => ({ ...state, threadRecipients }),
        setMessageThread: (state, messageThread) => ({ ...state, messageThread }),
        setMessageThreads: (state, messageThreads) => ({ ...state, messageThreads }),
        addNewMessageToThread: (state, messages) => produce(state, (draftState) => {
            (draftState.messageThread?.messages ?? []).push(...messages);
        }),
        setReplyMessage: (state, replyMessage) => ({ ...state, replyMessage }),
    },

    selectors: {
        getThreadMetadata: (state) => state.threadMetadata,
        getThreadRecipients: (state) => state.threadRecipients,
        getSelectableDimensions: (state) => state.selectableDimensions,
        getMessageThread: (state) => state.messageThread,
        getMessageThreads: (state) => state.messageThreads,
        getReplyMessage: (state) => state.replyMessage,
    },

    effects: (dispatch) => ({
        async fetchThreadMetadata() {
            try {
                const response = await api.get(`/api/v2/instant-message-metadata`);

                this.setThreadMetadata(response.data ?? []);
            } catch (e) {
                console.error(e);
            }
        },

        async fetchSelectableDimensions(userId) {
            try {
                const route = `/api/v2/user/${userId}/user-attached-dimensions`;
                const response = await api.get(route);
                const data = response.data;
                const selectableDimensions = data.map((r) => r.dimension);

                this.setSelectableDimensions(selectableDimensions);
            } catch (e) {
                console.error(e);
            }
        },

        async fetchThreadRecipients() {
            try {
                const response = await api.get(`/api/v2/instant-message-threads/recipients`);

                this.setThreadRecipients(response.data ?? []);
            } catch (e) {
                console.error(e);
            }
        },

        async fetchMessageThreads(threadType) {
            try {
                const response = await api.get(`/api/v2/instant-message-threads?type=${threadType}`);
                this.setMessageThreads(response.data ?? []);
            } catch (e) {
                console.error(e);
            }
        },

        async fetchMessageThread(threadUuid) {
            try {
                const response = await api.get(`/api/v2/instant-message-threads/${threadUuid}`);

                if (response.status === 'ok') {
                    this.setMessageThread(response.data ?? {});
                    return response.data?.messages ?? [];
                }
                return response;
            } catch (e) {
                console.error(e);
            }
        },

        async fetchNewMessages({ threadUuid, lastMessageId }) {
            try {
                const response = await api.get(`/api/v2/instant-message-threads/${threadUuid}/messages/${lastMessageId}`);
                if (response.status === 'ok') {
                    this.addNewMessageToThread(response.data?.messages);
                    return response.data;
                }
            } catch (e) {
                console.error(e);
            }
        },

        async postInstantMessageThread(values) {
            try {
                return await api.post('/api/v2/instant-message-threads', values);
            } catch (e) {
                console.error(e);
            }
        },

        async postInstantMessageThreadReply({ threadUuid, values }) {
            try {
                const response = await api.post(`/api/v2/instant-message-threads/${threadUuid}`, values);
                if (response.status === 'ok') {
                    this.setMessageThread(response.data ?? []);
                    return response.data ?? [];
                } else {
                    dispatch.notifications.addError(_trans('Viestiin ei voitu vastata.', {}, 'instant_messsages'));
                }
                return response;
            } catch (e) {
                console.error(e);
            }
        },

        async postInstantMessageThreadAssign({ threadUuid }) {
            try {
                return await api.post(`/api/v2/instant-message-threads/${threadUuid}/assign`);
            } catch (e) {
                console.error(e);
            }
        },

        async postInstantMessageThreadReject({ threadUuid }) {
            try {
                return await api.post(`/api/v2/instant-message-threads/${threadUuid}/reject`);
            } catch (e) {
                console.error(e);
            }
        },

        // Arkistointi
        async postInstantMessageThreadArchive({ threadUuid }) {
            try {
                return await api.post(`/api/v2/instant-message-threads/${threadUuid}/archive`);
            } catch (e) {
                console.error(e);
            }
        },

        // Arkistosta palauttaminen
        async postInstantMessageThreadRestore({ threadUuid }) {
            try {
                return await api.post(`/api/v2/instant-message-threads/${threadUuid}/restore`);
            } catch (e) {
                console.error(e);
            }
        },

        async deleteInstantMessage({ threadUuid, messageId }) {
            try {
                const response = await api.del(`/api/v2/instant-message-threads/${threadUuid}/messages/${messageId}`);
                if (response.status === 'ok') {
                    this.setMessageThread(response.data ?? []);
                    return response;
                }
            } catch (e) {
                dispatch.notifications.addError(_trans('Viestiä ei voitu poistaa.', {}, 'communication'));
                console.error(e);
            }
        }
    })
};

export const loadingSelectors = {
    isLoadingThreadMetadata: (state) => state.loading.effects.instantMessages.fetchThreadMetadata ?? false,
    isLoadingMessageThread: (state) => state.loading.effects.instantMessages.fetchMessageThread ?? false,
    isLoadingMessageThreads: (state) => state.loading.effects.instantMessages.fetchMessageThreads ?? false,
};
