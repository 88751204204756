import api from 'api';

export const additionalAddresses = {
    state: [],

    reducers: {
        setAdditionalAddresses: (state, additionalAddresses) => additionalAddresses,
    },

    selectors: {
        getAdditionalAddresses: (state) => state,
        getInvoicingAddress: (state) => state.find((address) => address.isInvoicingAddress),
    },

    effects: () => ({
        async fetchAdditionalAddresses(user) {
            try {
                const response = await api.get(`/api/v2/user/${user}/additional_address`);
                this.setAdditionalAddresses(response.data ?? []);
            } catch (e) {
                console.error(e);
            }
        },

        async postAdditionalAddress(model) {
            try {
                return await api.post(`/api/v2/user/${model.userId}/additional_address`, model);
            } catch (e) {
                console.error(e);
            }
        },

        async deleteAdditionalAddress(aaId) {
            try {
                return await api.del(`/api/v2/user/additional_address/${aaId}`);
            } catch (e) {
                console.error(e);
            }
        },

        async updateAdditionalAddress(model) {
            try {
                return await api.put(`/api/v2/user/${model.userId}/additional_address/${model.id}`, model);
            } catch (e) {
                console.error(e);
            }
        },
    })
};
