import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import TableSalaryChoices from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/shared/TableSalaryChoices';
import { fieldNames } from 'ContractV3/constants/fieldNames';
import { hiddenFields } from 'ContractV3/constants/hiddenFields';
import { resolveChoiceChainOptions } from 'ContractV3/utils/resolveChoiceChainOptions';
import { ERROR_NOT_FOUND_FOR_DATE, TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE } from 'shared/constants/tableSalary';
import { resolveArrayName } from 'shared/ReForm/utils/resolveArrayName';

/**
 * Kulukorvauksen erityislisän valinnat.
 * @param arrayName
 * @param name
 * @param originalName
 * @param attributes
 * @returns {JSX.Element}
 * @constructor
 */
const CostReimbursementSpecialAllowanceAttributes = ({ arrayName, name, originalName, attributes }) => {
    const dispatch = useDispatch();
    const { hasFixedNoOp } = attributes;
    // Taulukkopalkan arvot (salary.salaries.NN.costReimbursementSpecialAllowanceAttributes)
    const [field,,] = useField(name);

    // Onko taulukkoliksat käytössä
    const [isTableSalaryInUseField] = useField(resolveArrayName(arrayName, fieldNames.IS_TABLE_SALARY_IN_USE));
    const isTableSalaryInUse = isTableSalaryInUseField?.value ?? true;
    const hasCostReimbursementSpecialAllowanceTableSalaries = useSelector(select.contract.hasCostReimbursementSpecialAllowanceTableSalaries);
    const [,,isPendingCostReimbursementSpecialAllowanceHelpers] = useField(resolveArrayName(arrayName, fieldNames.IS_PENDING_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));

    const metadataSpecialAllowance = useSelector(select.tableSalary.getCostReimbursementSpecialAllowanceMetadata);

    // Salaarityyppi
    const [salaryTypeField] = useField(fieldNames.SALARY_TYPE);
    const salaryType = salaryTypeField.value;

    // TES
    const [collectiveAgreementField] = useField(fieldNames.COLLECTIVE_AGREEMENT);
    const collectiveAgreement = collectiveAgreementField.value;
    const tableSalaryDate = useSelector(select.contract.getTableSalaryDate);

    // Kulukorvauksen erityislisä
    const [,,costReimbursementSpecialAllowanceHelpers] = useField(resolveArrayName(arrayName, fieldNames.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE));
    const [,,costReimbursementSpecialAllowanceLabelHelpers] = useField(resolveArrayName(arrayName, hiddenFields.COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_LABEL));

    // Päivitetään valinnat vain jos jokin riippuvuus muuttuu
    const options = useMemo(() => {
        const values = field.value || {};
        return (
            metadataSpecialAllowance.map((option) => ({
                ...option,
                label: _transObj(option.label),
                options: resolveChoiceChainOptions(option, { values, salaryType, tableSalaryDate })
                    // Jos asetettu ettei koskaan makseta valitaan Ei makseta suoraan ettei asiakkaat ränkkää
                    .filter((option) => hasFixedNoOp
                        ? option.value === 'no-op'
                        : true)
            }))
        );
    }, [metadataSpecialAllowance, field.value, salaryType, hasFixedNoOp, tableSalaryDate]);

    if (! isTableSalaryInUse || ! hasCostReimbursementSpecialAllowanceTableSalaries) return null;

    return (
        <TableSalaryChoices
            arrayName={arrayName}
            options={options}
            name={name}
            originalName={originalName}
            onLastOptionChange={(option) => {
                const salaryLabelSelect = metadataSpecialAllowance.find((data) => data.useSalaryLabel ?? false);
                if (salaryLabelSelect) {
                    costReimbursementSpecialAllowanceLabelHelpers.setValue(`${_trans('Kulukorvauksen erityislisä', {}, 'jobContract')}: ${option.label}`);
                }
            }}
            onChange={(values) => {
                const hasNoop = Object.values(values.values).includes('no-op');
                if (hasNoop) {
                    isPendingCostReimbursementSpecialAllowanceHelpers.setValue(false);
                    return costReimbursementSpecialAllowanceHelpers.setValue('0');
                }

                // Haetaan taulukkopalkat
                dispatch.contract.fetchTableSalary({
                    collectiveAgreement,
                    values: values.values,
                    date: tableSalaryDate,
                    types: [
                        TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE,
                    ]
                }).then((salary) => {
                    if (salary === ERROR_NOT_FOUND_FOR_DATE) {
                        costReimbursementSpecialAllowanceHelpers.setValue('0.00');
                        isPendingCostReimbursementSpecialAllowanceHelpers.setValue(true);

                        return;
                    }
                    costReimbursementSpecialAllowanceHelpers.setValue(salary);
                    isPendingCostReimbursementSpecialAllowanceHelpers.setValue(false);
                });
            }}
        />
    );
};

CostReimbursementSpecialAllowanceAttributes.propTypes = {
    arrayName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    originalName: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
        hasFixedNoOp: PropTypes.bool,
    }).isRequired,
};

export default CostReimbursementSpecialAllowanceAttributes;

