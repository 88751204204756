import PropTypes from 'prop-types';
import Customer from 'shared/ReForm/containers/CustomBlocks/ContractV3/Form/Parties/Customer';

/**
 * Lapsi. Käytännössä filtteröi hoidettavat-listalta 18v ja yli pois.
 * @param name
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const Child = ({ name, ...rest }) => <Customer name={name} {...rest} />;

Child.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Child;
